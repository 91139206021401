import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { FormInput } from "../FormInput";
import Select from "react-select";
import { Label } from "../FormInput/styled";
import { DropdownComponent } from "../DropdownComponent";
import {
  addFeesData,
  getUsersWithoutPagination,
  searchEngLangSchools,
} from "../../Services/Function";
import ReactDatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";
import { ToggleButton } from "../ToggleButton";
import { CountryInputComponent } from "../CountryInputComponent";
import { debounce } from "debounce";
import * as Yup from "yup";

interface IAddFeesModalProps {
  show: boolean;
  onHide: any;
  reloadData?: any;
  feeType?: any;
}

export const AddFeesModal: React.FunctionComponent<IAddFeesModalProps> = (
  props
) => {
  const [loading, setloading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [users, setusers] = useState([]);
  const [selectedUser, setselectedUser] = useState<any>("");
  const [checkedBy, setcheckedBy] = useState<any>("");
  const [dateSubmmited, setdateSubmmited] = React.useState<any>(null);
  const [phoneNo, setphoneNo] = React.useState("");
  const [dateOfAppointment, setdateOfAppointment] = useState<any>(null);
  const [dateOfTest, setdateOfTest] = useState<any>(null);
  const [createdDate, setCreatedDate] = useState<any>(null);
  const [arrivalDate, setarrivalDate] = useState<any>(null);
  const [salesPerson, setsalesPerson] = useState<any>("");
  const [operations, setoperations] = useState<any>("");
  const [accountManager, setaccountManager] = useState<any>("");
  const [docSent, setdocSent] = useState(false);
  const [moneyTransferred, setmoneyTransferred] = useState(false);
  const [selectedSchool, setselectedSchool] = useState<any>("");
  const [addSchool, setaddSchool] = useState(false);
  const [schools, setschools] = useState([]);
  const [schoolSearch, setschoolSearch] = useState("");
  const [errorArray, seterrorArray] = useState<any>([]);

  useEffect(() => {
    async function fetchData() {
      setloading(true);
      const res = await getUsersWithoutPagination();

      setusers(res);
      setloading(false);
    }
    fetchData();
  }, []);

  const searchSchool = debounce(async (e: any) => {
    if (!e.target.value) {
      setschools([]);
    } else {
      const res = await searchEngLangSchools({ text: e.target.value });
      setschools(res?.results);

      // If the entered value is not found in the results
    }
  }, 500);

  const options = [
    { value: "EnglishLanguage", label: "English Language" },
    { value: "Foundation", label: "Foundation" },
    { value: "Bachelor", label: "Bachelors" },
    { value: "Masters", label: "Masters" },
    { value: "Translation", label: "Translation" },
    { value: "PersonalStatement", label: "Personal Statement" },
    { value: "CV", label: "CV" },
    { value: "PHD", label: "PHD" },
    // { value: "UCAS", label: "UCAS" },
  ];

  const handleChange = (selectedItems: any) => {
    setSelectedOption(selectedItems);
  };
  const excludedCountries = ["IL"];
  const filteredCountries = getCountries().filter(
    (country) => !excludedCountries.includes(country)
  );

  const createValidationSchema = (feeType: string) => {
    switch (feeType) {
      case "student-visa":
        return Yup.object().shape({
          // amount: Yup.number().required("Amount is required"),
          vfsRefNo: Yup.string().required("VFS Reference Number is required"),
          vfsEmail: Yup.string().required("VFS Email is required"),
          // formOfPayment: Yup.string().required("Form of Payment is required"),
          fullName: Yup.string().required("Full Name is required"),
          // paymentRecieved: Yup.string().required(
          //   "Payment Recieved is required"
          // ),
        });

      case "ielts-booking":
        return Yup.object().shape({
          passportNo: Yup.string().required("Passport Number is required"),
          email: Yup.string().required("Email is required"),
          password: Yup.string().required("Password is required"),
          typeOfTest: Yup.string().required("Type of Test is required"),
          formOfTest: Yup.string().required("Form of Test is required"),
          refNo: Yup.string().required("Reference Number is required"),
          fullName: Yup.string().required("Full Name is required"),
        });
      case "office-fees":
        return Yup.object().shape({
          amount: Yup.number().required("Amount is required"),
          fullName: Yup.string().required("Full Name is required"),
        });

      case "english-self-funded":
        return Yup.object().shape({
          fullName: Yup.string().required("Full Name is required"),
          noOfWeeks: Yup.number().required("Number of Weeks is required"),
          location: Yup.string().required("Location is required"),
          amountPaidKd: Yup.number().required(
            "Payment Amount in KD is required"
          ),
        });

      default:
        return Yup.object().shape({
          amount: Yup.number().required("Amount is required"),
          desc: Yup.string().required("Description is required"),
          type: Yup.array().required("Type is required"),
          vfsRefNo: Yup.string().notRequired(),
          vfsEmail: Yup.string().notRequired(),
          // ofcFee: Yup.string().notRequired(),
          formOfPayment: Yup.string().notRequired(),
          passportNo: Yup.string().notRequired(),
          email: Yup.string().notRequired(),
          password: Yup.string().notRequired(),
          dateOfTest: Yup.date().notRequired(),
          typeOfTest: Yup.string().notRequired(),
          formOfTest: Yup.string().notRequired(),
          refNo: Yup.string().notRequired(),
          fullName: Yup.string().required("Full Name is required"),
          noOfWeeks: Yup.number().notRequired(),
          location: Yup.string().notRequired(),
          amountPaidKd: Yup.number().notRequired(),
        });
    }
  };

  const validateCustomFields = (feeType: any) => {
    const errors = [];
    if (feeType === "office-fees") {
      if (!selectedUser)
        errors.push({ selectedUser: "Sales Person is required" });
      if (!createdDate) errors.push({ createdDate: "Date is required" });
      if (!selectedOption)
        errors.push({ selectedOption: "Fee Type is required" });
    }
    if (feeType === "student-visa") {
      if (!dateOfAppointment)
        errors.push({ dateOfAppointment: "Date of Appointment is required" });
      if (!selectedUser)
        errors.push({ selectedUser: "Sales Person is required" });
      if (!checkedBy) errors.push({ checkedBy: "Checked By is required" });
      if (!dateSubmmited)
        errors.push({ dateSubmmited: "Date Submitted is required" });
      if (!phoneNo) errors.push({ phoneNo: "Phone Number is required" });
    }

    if (feeType === "ielts-booking") {
      if (!dateOfTest) errors.push({ dateOfTest: "Date of Test is required" });
      if (!selectedUser)
        errors.push({ selectedUser: "Sales Person is required" });
      if (!dateSubmmited)
        errors.push({ dateSubmmited: "Date Submitted is required" });
      if (!phoneNo) errors.push({ phoneNo: "Phone Number is required" });
    }

    if (feeType === "english-self-funded") {
      if (!arrivalDate)
        errors.push({ arrivalDate: "Arrival Date is required" });
      if (!dateSubmmited)
        errors.push({ dateSubmmited: "Date Submitted is required" });
      if (!phoneNo) errors.push({ phoneNo: "Phone Number is required" });
      if (!selectedSchool)
        errors.push({ selectedSchool: "School is required" });
      if (!salesPerson)
        errors.push({ salesPerson: "Sales Person is required" });
      if (!accountManager)
        errors.push({ accountManager: "Account Manager is required" });
      if (!operations) errors.push({ operations: "Operation is required" });
    }

    return errors;
  };
  const validationSchema = createValidationSchema(props.feeType);

  const formatDateInKuwait = (date: Date) => {
    if (!date) return null;

    // Kuwait is UTC+3, so we need to adjust the time zone
    const kuwaitTimeOffset = 3 * 60; // Kuwait is UTC+3 in minutes

    // Get the UTC date without affecting the time
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );

    // Add Kuwait time offset directly
    const kuwaitDate = new Date(utcDate.getTime() + kuwaitTimeOffset * 60000);

    // Return date in YYYY-MM-DD format (ignore time component)
    return kuwaitDate.toISOString().split("T")[0];
  };

  return (
    <Modal
      id="myModal"
      show={props.show}
      onHide={() => props.onHide()}
      centered
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          amount: "",
          desc: "",
          type: [],
          vfsRefNo: "",
          vfsEmail: "",
          ofcFee: "",
          formOfPayment: "",
          passportNo: "",
          email: "",
          password: "",
          dateOfTest: "",
          typeOfTest: "",
          formOfTest: "",
          refNo: "",
          fullName: "",
          noOfWeeks: "",
          location: "",
          amountPaidKd: "",
          paymentRecieved: "",
        }}
        onSubmit={async (
          values: any,
          { setSubmitting }: FormikHelpers<any>
        ) => {
          setloading(true);
          const customErrors: any = validateCustomFields(props.feeType);

          if (customErrors.length > 0) {
            seterrorArray(customErrors);
            return;
          }

          let tag;
          if (props.feeType === "office-fees") {
            tag = {
              amount: parseInt(values.amount),
              description: values.desc,
              type: selectedOption,
              salesPerson: selectedUser.id,
              fullName: values.fullName,
            };
          }

          if (props.feeType === "student-visa") {
            tag = {
              dateSubmitted: formatDateInKuwait(dateSubmmited),
              fullName: values.fullName,
              phoneNo: phoneNo,
              vfsRefNo: values.vfsRefNo,
              vfsEmail: values.vfsEmail,
              dateOfAppointment: formatDateInKuwait(dateOfAppointment),
              amount: parseInt(values.amount),
              formOfPayment: values.formOfPayment,
              paymentRecieved: values.paymentRecieved,
              incharge: selectedUser.id,
              checkedBy: checkedBy.id,
              vfsUploaded: values.vfsUploaded,
            };
          }

          if (props.feeType === "ielts-booking") {
            tag = {
              dateSubmitted: formatDateInKuwait(dateSubmmited),
              fullName: values.fullName,
              passportNo: values.passportNo,
              phoneNo: phoneNo,
              email: values.email,
              formOfTest: values.formOfTest,
              password: values.password,
              typeOfTest: values.typeOfTest,
              refNo: values.refNo,
              dateOfTest: formatDateInKuwait(dateOfTest),
              incharge: selectedUser.id,
              docSent: values.docSent,
            };
          }

          if (props.feeType === "english-self-funded") {
            tag = {
              moneyTransferdToSchool: moneyTransferred,
              dateSubmitted: formatDateInKuwait(dateSubmmited),
              arrivalDate: formatDateInKuwait(arrivalDate),
              fullName: values.fullName,
              phoneNo: phoneNo,
              location: values.location,
              school: selectedSchool,
              noOfWeeks: parseInt(values.noOfWeeks),
              amount: parseInt(values.amountPaidKd),
              salesPerson: salesPerson,
              accountManager: accountManager,
              operation: operations,
            };
          }

          await addFeesData({
            feeType: props.feeType,
            tag,
            createdDate: !createdDate ? new Date() : createdDate,
          });
          props.reloadData(props.feeType);
          setdateSubmmited(null);
          values.fullName = "";
          values.password = "";
          values.passportNo = "";
          values.email = "";
          values.refNo = "";
          setphoneNo("");
          values.formOfTest = "";
          values.vfsRefNo = "";
          values.vfsEmail = "";
          setaccountManager("");
          setoperations("");
          setsalesPerson("");
          values.noOfWeeks = "";
          setselectedSchool({});
          values.location = "";
          values.amountPaidKd = "";
          setdateOfAppointment(new Date());
          values.ofcFee = "";
          values.formOfPayment = "";
          values.paymentRecieved = "";
          setselectedUser("");
          setcheckedBy("");
          values.vfsUploaded = "";
          setdateOfTest(new Date());
          setloading(false);
          props.onHide();
        }}
      >
        {({ values, isSubmitting, errors, touched }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="modal-title">{`Add ${props.feeType
                .split("-")
                .map(
                  (word: any) => word.charAt(0).toUpperCase() + word.slice(1)
                )
                .join(" ")}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="h-100"
              style={{ padding: 15, overflow: "scroll" }}
            >
              {props.feeType === "office-fees" ? (
                <>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Fee Type</Label>
                    <Select
                      isMulti
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "#7F56D9" : "#d7dfe9",
                          boxShadow: "none",
                        }),
                      }}
                      value={selectedOption}
                      onChange={handleChange}
                      options={options}
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.selectedOption)[0]
                          ?.selectedOption
                      }
                    </div>
                  </div>
                  <div>
                    <FormInput
                      id="fullName"
                      name="fullName"
                      placeholder=""
                      type="text"
                      label="Full Name"
                    />
                    {errors.fullName && touched.fullName ? (
                      <div className="error">{errors.fullName}</div>
                    ) : null}
                  </div>
                  <div>
                    <FormInput
                      id="amount"
                      name="amount"
                      placeholder=""
                      type="number"
                      label="Amount"
                    />
                    {errors.amount && touched.amount ? (
                      <div className="error">{errors.amount}</div>
                    ) : null}
                  </div>
                  <div>
                    <FormInput
                      id="desc"
                      name="desc"
                      placeholder=""
                      type="text"
                      label="Description"
                    />
                    {errors.desc && touched.desc ? (
                      <div className="error">{errors.desc}</div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Sales Person</Label>
                    <DropdownComponent
                      children={users}
                      onClick={(data: any) => setselectedUser(data)}
                      className=""
                      selected={selectedUser.name}
                      placeholder={""}
                      displayName
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.selectedUser)[0]
                          ?.selectedUser
                      }
                    </div>
                  </div>
                  <div>
                    <Label>Created Date</Label>
                    <ReactDatePicker
                      selected={createdDate}
                      className="form-input"
                      onChange={(date: any) => setCreatedDate(date)}
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.createdDate)[0]
                          ?.createdDate
                      }
                    </div>
                  </div>
                </>
              ) : props.feeType === "student-visa" ? (
                <>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Date Submmited</Label>
                    <ReactDatePicker
                      selected={dateSubmmited}
                      onChange={async (date: any) => {
                        setdateSubmmited(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.dateSubmmited)[0]
                          ?.dateSubmmited
                      }
                    </div>
                  </div>
                  <div>
                    <FormInput
                      id="fullName"
                      name="fullName"
                      placeholder=""
                      type="text"
                      label="Full Name"
                    />
                    {errors.fullName && touched.fullName ? (
                      <div className="error">{errors.fullName}</div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Phone Number</Label>
                    <PhoneInput
                      international
                      defaultCountry="KW"
                      countries={filteredCountries}
                      value={phoneNo}
                      onChange={(e: any) => setphoneNo(e)}
                    />
                    <div style={{ margin: 0 }} className="error">
                      {errorArray.filter((a: any) => a.phoneNo)[0]?.phoneNo}
                    </div>
                  </div>

                  <div>
                    <FormInput
                      id="vfsRefNo"
                      name="vfsRefNo"
                      placeholder=""
                      type="text"
                      label="Vfs Refrence Number"
                    />
                    {errors.vfsRefNo && touched.vfsRefNo ? (
                      <div className="error">{errors.vfsRefNo}</div>
                    ) : null}
                  </div>
                  <div>
                    <FormInput
                      id="vfsEmail"
                      name="vfsEmail"
                      placeholder=""
                      type="text"
                      label="Vfs Email"
                    />
                    {errors.vfsEmail && touched.vfsEmail ? (
                      <div className="error">{errors.vfsEmail}</div>
                    ) : null}
                  </div>
                  <div>
                    <FormInput
                      id="amount"
                      name="amount"
                      placeholder=""
                      type="text"
                      label="Office Fee"
                    />
                    {errors.amount && touched.amount ? (
                      <div className="error">{errors.amount}</div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Date of Appointment</Label>
                    <ReactDatePicker
                      selected={dateOfAppointment}
                      onChange={async (date: any) => {
                        setdateOfAppointment(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.dateOfAppointment)[0]
                          ?.dateOfAppointment
                      }
                    </div>
                  </div>
                  <div>
                    <Label>Form of Payment</Label>

                    <Field
                      className="form-input"
                      as="select"
                      name="formOfPayment"
                    >
                      <option value="" disabled selected>
                        Select an option
                      </option>
                      {["Cash", "Payment Link"].map((a) => (
                        <option value={a}>{a}</option>
                      ))}
                    </Field>
                    {errors.formOfPayment && touched.formOfPayment ? (
                      <div className="error">{errors.formOfPayment}</div>
                    ) : null}
                  </div>

                  <div>
                    <Label>Payment Recieved</Label>

                    <Field
                      className="form-input"
                      as="select"
                      name="paymentRecieved"
                    >
                      <option value="" disabled selected>
                        Select an option
                      </option>
                      {["Yes", "No"].map((a) => (
                        <option value={a}>{a}</option>
                      ))}
                    </Field>
                    {errors.paymentRecieved && touched.paymentRecieved ? (
                      <div className="error">{errors.paymentRecieved}</div>
                    ) : null}
                  </div>

                  <div style={{ marginBottom: 24 }}>
                    <Label>Operation/Visa Officer</Label>
                    <DropdownComponent
                      children={users}
                      onClick={(data: any) => setselectedUser(data)}
                      className=""
                      selected={selectedUser.name}
                      placeholder={""}
                      displayName
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.selectedUser)[0]
                          ?.selectedUser
                      }
                    </div>
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Checked By</Label>
                    <DropdownComponent
                      children={users}
                      onClick={(data: any) => setcheckedBy(data)}
                      className=""
                      selected={checkedBy.name}
                      placeholder={""}
                      displayName
                    />
                    <div style={{ margin: 0 }} className="error">
                      {errorArray.filter((a: any) => a.checkedBy)[0]?.checkedBy}
                    </div>
                  </div>

                  <div>
                    <Label>
                      <Field type="checkbox" name="vfsUploaded" />
                      Vfs Uploaded
                    </Label>
                  </div>
                </>
              ) : props.feeType === "english-self-funded" ? (
                <>
                  <div>
                    <Label>Money Transferred</Label>
                    <br />
                    <ToggleButton
                      style={{ margin: "10px 0" }}
                      label={"moneyTransferred"}
                      onChange={() => {
                        setmoneyTransferred(!moneyTransferred);
                      }}
                      checked={moneyTransferred}
                    />
                  </div>

                  <div style={{ marginBottom: 24 }}>
                    <Label>Date Submmited</Label>
                    <ReactDatePicker
                      selected={dateSubmmited}
                      onChange={async (date: any) => {
                        setdateSubmmited(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.dateSubmmited)[0]
                          ?.dateSubmmited
                      }
                    </div>
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Arrival Date</Label>
                    <ReactDatePicker
                      selected={arrivalDate}
                      onChange={async (date: any) => {
                        setarrivalDate(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.arrivalDate)[0]
                          ?.arrivalDate
                      }
                    </div>
                  </div>
                  <div>
                    <FormInput
                      id="fullName"
                      name="fullName"
                      placeholder=""
                      type="text"
                      label="Full Name"
                    />
                    {errors.fullName && touched.fullName ? (
                      <div className="error">{errors.fullName}</div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Phone Number</Label>
                    <PhoneInput
                      international
                      defaultCountry="KW"
                      countries={filteredCountries}
                      value={phoneNo}
                      onChange={(e: any) => setphoneNo(e)}
                    />
                    <div style={{ margin: 0 }} className="error">
                      {errorArray.filter((a: any) => a.phoneNo)[0]?.phoneNo}
                    </div>
                  </div>
                  <div>
                    <FormInput
                      id="location"
                      name="location"
                      placeholder=""
                      type="text"
                      label="Location"
                    />
                    {errors.location && touched.location ? (
                      <div className="error">{errors.location}</div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>
                      {selectedSchool.name
                        ? "Select School"
                        : "Select a school from dropdown"}
                    </Label>
                    <CountryInputComponent
                      selectedCountry={selectedSchool}
                      autoComplete="new-password"
                      setsearchText={setschoolSearch}
                      setselectedCountry={setselectedSchool}
                      removeContry={() => setselectedSchool("")}
                      arabic={false}
                      setaddSchool={setaddSchool}
                      addSchool={addSchool}
                      searchCountry={searchSchool}
                      engSchoolDd
                      countries={schools}
                      setcountries={setschools}
                      searchText={schoolSearch}
                      placeholder={""}
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.selectedSchool)[0]
                          ?.selectedSchool
                      }
                    </div>
                  </div>

                  <div>
                    <FormInput
                      id="noOfWeeks"
                      name="noOfWeeks"
                      placeholder=""
                      type="number"
                      label="No. of weeks"
                    />
                    {errors.noOfWeeks && touched.noOfWeeks ? (
                      <div className="error">{errors.noOfWeeks}</div>
                    ) : null}
                  </div>

                  <div>
                    <FormInput
                      id="amountPaidKd"
                      name="amountPaidKd"
                      placeholder=""
                      type="number"
                      label="Payment Amount in KD"
                    />
                    {errors.amountPaidKd && touched.amountPaidKd ? (
                      <div className="error">{errors.amountPaidKd}</div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Sales</Label>
                    <DropdownComponent
                      children={users}
                      onClick={(data: any) => setsalesPerson(data)}
                      className=""
                      selected={salesPerson.name}
                      placeholder={""}
                      displayName
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.salesPerson)[0]
                          ?.salesPerson
                      }
                    </div>
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Account Manager</Label>
                    <DropdownComponent
                      children={users}
                      onClick={(data: any) => setaccountManager(data)}
                      className=""
                      selected={accountManager.name}
                      placeholder={""}
                      displayName
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.accountManager)[0]
                          ?.accountManager
                      }
                    </div>
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Operations</Label>
                    <DropdownComponent
                      children={users}
                      onClick={(data: any) => setoperations(data)}
                      className=""
                      selected={operations.name}
                      placeholder={""}
                      displayName
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.operations)[0]
                          ?.operations
                      }
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Date Submmited</Label>
                    <ReactDatePicker
                      selected={dateSubmmited}
                      onChange={async (date: any) => {
                        setdateSubmmited(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.dateSubmmited)[0]
                          ?.dateSubmmited
                      }
                    </div>
                  </div>
                  <div>
                    <FormInput
                      id="fullName"
                      name="fullName"
                      placeholder=""
                      type="text"
                      label="Full Name"
                    />
                    {errors.fullName && touched.fullName ? (
                      <div className="error">{errors.fullName}</div>
                    ) : null}
                  </div>
                  <div>
                    <FormInput
                      id="passportNo"
                      name="passportNo"
                      placeholder=""
                      type="text"
                      label="Passport Number"
                    />
                    {errors.passportNo && touched.passportNo ? (
                      <div className="error">{errors.passportNo}</div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Phone Number</Label>
                    <PhoneInput
                      international
                      defaultCountry="KW"
                      countries={filteredCountries}
                      value={phoneNo}
                      onChange={(e: any) => setphoneNo(e)}
                    />
                    <div style={{ margin: 0 }} className="error">
                      {errorArray.filter((a: any) => a.phoneNo)[0]?.phoneNo}
                    </div>
                  </div>
                  <div>
                    <FormInput
                      id="email"
                      name="email"
                      placeholder=""
                      type="text"
                      label="email"
                    />
                    {errors.email && touched.email ? (
                      <div className="error">{errors.email}</div>
                    ) : null}
                  </div>
                  <div>
                    <FormInput
                      id="password"
                      name="password"
                      placeholder=""
                      type="password"
                      label="Password"
                    />
                    {errors.password && touched.password ? (
                      <div className="error">{errors.password}</div>
                    ) : null}
                  </div>
                  <div>
                    {" "}
                    <FormInput
                      id="refNo"
                      name="refNo"
                      placeholder=""
                      type="text"
                      label="Refrence Number"
                    />
                    {errors.refNo && touched.refNo ? (
                      <div className="error">{errors.refNo}</div>
                    ) : null}
                  </div>
                  <div>
                    <Label>Type of Test</Label>

                    <Field className="form-input" as="select" name="typeOfTest">
                      <option value="" disabled selected>
                        Select an option
                      </option>
                      {["IELTS", "UKVI", "OSR"].map((a) => (
                        <option value={a}>{a}</option>
                      ))}
                    </Field>
                    {errors.typeOfTest && touched.typeOfTest ? (
                      <div className="error">{errors.typeOfTest}</div>
                    ) : null}
                  </div>
                  <div>
                    <Label>Form of Test</Label>

                    <Field className="form-input" as="select" name="formOfTest">
                      <option value="" disabled selected>
                        Select an option
                      </option>
                      {["Paper", "Computer"].map((a) => (
                        <option value={a}>{a}</option>
                      ))}
                    </Field>
                    {errors.formOfTest && touched.formOfTest ? (
                      <div className="error">{errors.formOfTest}</div>
                    ) : null}
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Date of Test</Label>
                    <ReactDatePicker
                      selected={dateOfTest}
                      onChange={async (date: any) => {
                        setdateOfTest(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.dateOfTest)[0]
                          ?.dateOfTest
                      }
                    </div>
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Label>Operation/Visa Officer</Label>
                    <DropdownComponent
                      children={users}
                      onClick={(data: any) => setselectedUser(data)}
                      className=""
                      selected={selectedUser.name}
                      placeholder={""}
                      displayName
                    />
                    <div style={{ margin: 0 }} className="error">
                      {
                        errorArray.filter((a: any) => a.selectedUser)[0]
                          ?.selectedUser
                      }
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <Label>Documents sent</Label>
                    <ToggleButton
                      label={"docSent"}
                      onChange={() => setdocSent(!docSent)}
                      checked={docSent}
                    />
                  </div>
                </>
              )}{" "}
              <div className="d-flex" style={{ marginTop: 50 }}>
                <ButtonComponent
                  label={"Submit"}
                  loading={loading}
                  btnType={"dark"}
                  disabled={isSubmitting}
                />
                <ButtonComponent
                  label={"Close"}
                  loading={loading}
                  style={{ marginLeft: 20 }}
                  type="button"
                  onClick={() => props.onHide()}
                  btnType={"light"}
                />
              </div>
              {/* <ButtonComponent btnType="dark" loading={loading} label="Login" /> */}
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
