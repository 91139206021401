import moment from "moment-timezone";
import * as React from "react";
import { DotIcon } from "../../Assets";
import { updateLeads } from "../../Services/Function";
import { DetailModal } from "../DetailModal";
import { Loader } from "../Loader/Loader";
import {
  Status,
  Table,
  TBody,
  Td,
  TdEmail,
  TdText,
  Th,
  THead,
  Tr,
  UserSection,
  TdAnchor,
  OtherInfo,
} from "./styled";
import "./style.scss";
import { Dropdown } from "react-bootstrap";
import { LabelText, SubText } from "../DetailModal/styled";

interface ILeadsViewProps {
  students?: any;
  reloadStudents?: any;
  page: number;
  setselectedStatus: any;
}

export const LeadsView: React.FunctionComponent<ILeadsViewProps> = (props) => {
  // const navigate = useNavigate();
  const [showModal, setshowModal] = React.useState(false);
  const [selectedStudent, setselectedStudent] = React.useState({});
  const [showMenu, setshowMenu] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [statusMenu, setstatusMenu] = React.useState(false);

  const color = (value: string) => {
    return value === "New"
      ? "#1849A9"
      : value === "Sent Whatsapp"
        ? "#05603A"
        : value === "Applied"
          ? "#93370D"
          : "";
  };
  const background = (value: string) => {
    return value === "New"
      ? "#d1e9ff"
      : value === "Sent Whatsapp"
        ? "#D1FADF"
        : value === "Applied"
          ? "#FEF0C7"
          : "";
  };
  function getParameterByName(name: string, url: string) {
    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return "";

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const copyTextToClipboard = (textToCopy: any) => {
    navigator.clipboard
      .writeText(textToCopy.split(" ")[0])
      .then(() => {
        alert("Text has been copied to the clipboard");
      })
      .catch((err) => {
        console.error("Unable to copy!", err);
      });
  };

  function shortenText(text: string) {
    return text.length > 25 ? text.slice(0, 25) + "..." : text;
  }

  return (
    <div className="table-responsive">
      <Table className="table table-scroll">
        <THead>
          <Tr>
            <Th otherInfo></Th>
            <Th info scope="col">
              Name/Date
            </Th>
            <Th scope="col">Contact info</Th>
            <Th scope="col">Degree/IELTS</Th>
            <Th scope="col">GPA</Th>
            <Th scope="col">Lead status</Th>
            <Th scope="col">Ad Source</Th>
            <Th scope="col">Action</Th>
          </Tr>
        </THead>
        <React.Suspense fallback={<Loader />}>
          <TBody>
            {props.students?.map((z: any) => {
              const fullname = z.fullname || "";
              const subjects = z.subjects || "";
              const degree = z?.degree?.ar_name || "";
              const msg = `السلام عليكم ${fullname},
              
وصل لنا طلبك بالتقديم على تخصص ${subjects} - ${degree}.

هل أوراقك جاهزة للتقديم؟`;

              const waLink = `https://wa.me/${z.phoneNo.replace(
                /[^A-Z0-9]/gi,
                ""
              )}?text=${encodeURIComponent(msg)}`;

              return (
                <Tr
                  key={z.id}
                  //  onClick={() => navigate("/student/about/432423")}
                >
                  <Td otherInfo>
                    <div className="d-flex justify-content-center">
                      <OtherInfo
                        onClick={() => {
                          setshowModal(!showModal);
                          setselectedStudent(z);
                        }}
                      />
                    </div>
                  </Td>
                  <Td info style={{ paddingLeft: "0px !important" }}>
                    <UserSection>
                      {/* <div>
                    <Avatar size={40} />
                  </div> */}

                      <div
                        className="d-flex flex-column"
                        style={{ position: "relative" }}
                      >
                        <TdText>{shortenText(z.fullname)}</TdText>

                        <TdEmail style={{ width: "max-content" }}>
                          {moment(
                            new Date(
                              parseInt(z.id.toString().substring(0, 8), 16) *
                                1000
                            )
                          )
                            .tz("Asia/Kuwait")
                            .format("DD/MM/YYYY [at] hh:mm:a")}
                        </TdEmail>
                      </div>
                    </UserSection>
                  </Td>
                  <Td>
                    <div className="d-flex flex-column">
                      <TdText>
                        <TdAnchor
                          target="_blank"
                          href={`https://wa.me/${z.phoneNo.replace(
                            /[^A-Z0-9]/gi,
                            ""
                          )}`}
                        >
                          {`+${z.phoneNo.slice(0, 3)} ${z.phoneNo.slice(
                            3,
                            6
                          )} ${z.phoneNo.slice(6, 9)} ${z.phoneNo.slice(9)}`}
                        </TdAnchor>
                      </TdText>
                      <TdEmail>{z.nationality.english_name}</TdEmail>
                    </div>
                  </Td>
                  <Td>
                    <div className="d-flex flex-column">
                      <TdText>{z?.degree?.en_name}</TdText>
                      <TdEmail>{z.iltesScore || "Not Taken"}</TdEmail>
                    </div>
                  </Td>
                  <Td>
                    <div className="d-flex flex-column">
                      <TdText>{z.cgpa || "N/A"}</TdText>
                    </div>
                  </Td>
                  {loading && statusMenu === z.id ? (
                    <Td>Loading...</Td>
                  ) : (
                    <Td style={{ position: "relative" }}>
                      <Status
                        onClick={() => {
                          setstatusMenu(statusMenu ? !z.id : z.id);
                        }}
                        color={color(z.status)}
                        background={background(z.status)}
                      >
                        {z.status}
                      </Status>
                      {statusMenu === z.id ? (
                        <div
                          style={{
                            position: "absolute",
                            padding: 9,
                            background: "#fff",
                            zIndex: 99,
                            boxShadow: "0 2px 16px 0 rgb(0 0 0 / 8%)",
                            borderRadius: 8,
                          }}
                        >
                          {["New", "Sent Whatsapp", "Applied"].map(
                            (a: any, key: any) => (
                              <Status
                                key={key}
                                style={{ marginBottom: key === 3 ? 0 : 10 }}
                                onClick={async () => {
                                  try {
                                    setloading(true);
                                    await updateLeads(z.id, { status: a });
                                    await props.reloadStudents(props.page);
                                    setstatusMenu(false);
                                    setloading(false);
                                  } catch (error: any) {
                                    setloading(false);
                                    alert(error.response.data.message);
                                  }
                                }}
                                color={color(a)}
                                background={background(a)}
                              >
                                {a}
                              </Status>
                            )
                          )}
                        </div>
                      ) : null}
                    </Td>
                  )}

                  <Td>
                    <div className="d-flex flwx-row">
                      <Status
                        style={{ cursor: "copy" }}
                        color={z.source === "ulearn" ? "#fff" : "#fff"}
                        background={
                          z.source === "ulearn" ? "#299cf7" : "#FD3E60"
                        }
                        onClick={() => {
                          if (z?.webUrl?.includes("utm_source")) {
                            copyTextToClipboard(
                              getParameterByName("utm_source", z.webUrl)
                            );
                          }
                        }}
                      >
                        {z?.webUrl?.includes("utm_source")
                          ? getParameterByName(
                              "utm_source",
                              z.webUrl
                            )?.substring(0, 20)
                          : !z?.webUrl
                            ? "N/A"
                            : "No Ad"}
                      </Status>
                      {z?.webUrl?.includes("utm_source") ? (
                        <details style={{ marginLeft: 5 }}>
                          <summary> </summary>
                          <p
                            style={{
                              position: "absolute",
                              right: 80,
                              zIndex: 99,
                              borderRadius: 8,
                              backgroundColor: "#fff",
                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              padding: 10,
                            }}
                          >
                            <LabelText>Campaign: </LabelText>
                            <SubText>
                              {" "}
                              {getParameterByName("utm_campaign", z.webUrl)}
                            </SubText>
                            <br />
                            <LabelText>Medium:</LabelText>{" "}
                            <SubText>
                              {" "}
                              {getParameterByName("utm_medium", z.webUrl)}
                            </SubText>{" "}
                            <br />
                            <LabelText>Source:</LabelText>
                            <SubText>
                              {" "}
                              &nbsp;{getParameterByName("utm_source", z.webUrl)}
                            </SubText>{" "}
                          </p>
                        </details>
                      ) : null}
                    </div>
                  </Td>
                  <Td
                    style={{ position: "relative" }}
                    onClick={() => setshowMenu(showMenu ? !z.id : z.id)}
                  >
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dd-custom-toggle"
                        id="dropdown-custom-components"
                      >
                        <DotIcon style={{ cursor: "pointer" }} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dd-custom-menu">
                        {z.qualified ? (
                          <Dropdown.Item
                            className="dd-custom-items"
                            onClick={async () => {
                              try {
                                setloading(true);
                                await updateLeads(z.id, { qualified: false });
                                await props.reloadStudents(props.page);
                                setloading(false);
                              } catch (error: any) {
                                setloading(false);
                                alert(error.response.data.message);
                              }
                            }}
                            eventKey="1"
                          >
                            Move to unqualified
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            className="dd-custom-items"
                            onClick={async () => {
                              try {
                                await updateLeads(z.id, {
                                  qualified: true,
                                });
                                await props.reloadStudents(props.page);
                              } catch (error: any) {
                                alert(error.response.data.message);
                              }
                            }}
                          >
                            Move to qualified
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          target="_blank"
                          href={waLink}
                          className="dd-custom-items"
                        >
                          Send WhatsApp
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </React.Suspense>
      </Table>
      {showModal ? (
        <DetailModal
          students={selectedStudent}
          show={showModal}
          onHide={() => setshowModal(false)}
        />
      ) : null}
    </div>
  );
};
