import React, { Suspense, useState, useEffect } from "react";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./Components/GlobalStyles";
import { lightTheme, darkTheme } from "./Components/Theme";
import { ApplicationDetails, Header, Loader } from "./Components";
import { Route, Routes, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import {
  Courses,
  Dashboard,
  Fees,
  Leads,
  Login,
  News,
  Profile,
  StudentPage,
  Students,
  Users,
  WhatsApp,
  Notification,
} from "./pages";
import "react-circular-progressbar/dist/styles.css";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { ResetPassword } from "./pages/ResetPassword";
import { FireworkAnimation } from "./Components/Animations/FireworkAnimation";
import { dataAction } from "./state";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ForgotPassword } from "./pages/ForgotPassword";
// import "draft-js/dist/Draft.css";

function App() {
  let location = useLocation();
  const auth = useSelector((state: any) => state.auth);
  const data = useSelector((state: any) => state.data);

  const dispatch = useDispatch();
  // const { setAnimationFlag } = bindActionCreators(dataAction, dispatch);

  const [theme, setTheme] = useState("light");
  // const [showFireworks, setShowFireworks] = useState(true);

  // Show fireworks for 3 seconds only once
  useEffect(() => {
    // setAnimationFlag(false);
    // if (!data.setAnimationFlag) {
    //   const timer = setTimeout(() => {
    //     setAnimationFlag(true);
    //     setShowFireworks(false);
    //   }, 5000);
    //   return () => clearTimeout(timer);
    // }
  }, []);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      {/* {showFireworks && !data.animationFlag ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999, // Ensure it overlays other content
          }}
        >
          <FireworkAnimation height="100%" width="100%" />
        </div>
      ) : null} */}
      <GlobalStyles />
      {location.pathname === "/" ||
      location.pathname === "/reset-password" ||
      location.pathname === "/forgot-password" ? null : (
        <Header />
      )}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/students" element={<Students />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/whatsapp" element={<WhatsApp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {auth.userDetails.role === "63d2ec57fdc142001c0074e6" ||
          auth.userDetails.role === "63c92057dab279194bab8d8f" ? (
            <Route path="/setting" element={<Users />} />
          ) : null}
          <Route
            path={
              location.pathname.includes("/applicationDetails")
                ? "/student/:page/:id/:applicationId"
                : "/student/:page/:id"
            }
            element={<StudentPage />}
          />
          <Route path="/fees" element={<Fees />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/profile/:id" element={<Profile />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
