/* eslint-disable no-empty-pattern */
import React, { useState } from "react";
import { Card } from "./styled/login";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { authAction, State } from "../state/index";
import { ButtonComponent, FormInput } from "../Components";
import { Formik, Form, FormikHelpers } from "formik";
import { Navigate } from "react-router-dom";
import { Logo } from "../Assets";

type Props = {};

export function Login({}: Props) {
  interface Values {
    email: string;
    password: string;
  }

  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();
  const { login } = bindActionCreators(authAction, dispatch);
  const auth = useSelector((state: State) => state.auth);
  var authToken = localStorage.getItem("authToken");

  if (auth.isLoggedIn && authToken) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div
      className="row align-items-center justify-content-center"
      style={{ height: "100vh", background: "#7F56D9" }}
    >
      <Card>
        <>
          <Logo color="rgb(127, 86, 217)" />
          <Formik
            initialValues={{
              password: "",
              email: "",
            }}
            onSubmit={async (
              values: Values,
              { setSubmitting }: FormikHelpers<Values>
            ) => {
              setloading(true);
              try {
                await login(values.email, values.password);
                setloading(false);
              } catch (error) {
                console.log(error);
                alert(error);
              }
            }}
          >
            <Form style={{ width: "70%" }} className="d-flex flex-column">
              <FormInput
                id="email"
                name="email"
                placeholder="aamish@try.city"
                type="email"
                label="Email"
              />
              <FormInput
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                label="Password"
              />
              <div className="d-flex justify-content-between">
                <ButtonComponent
                  btnType="dark"
                  loading={loading}
                  label="Login"
                />
                <a
                  className="d-flex align-items-end"
                  style={{ color: "#7F56D9", fontSize: 13, fontWeight: 700 }}
                  href="/forgot-password"
                >
                  Forgot Password?
                </a>
              </div>
            </Form>
          </Formik>
        </>
      </Card>
    </div>
  );
}
