import * as React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import { LabelText, SubText, UniversityCon } from "./styled";

interface IDetailModalProps {
  onHide?: any;
  show?: boolean;
  students?: any;
  type?: any;
}

export const DetailModal: React.FunctionComponent<IDetailModalProps> = (
  props
) => {
  return (
    <Modal
      id="myModal"
      show={props.show}
      onHide={() => props.onHide()}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.students.fullname}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.type === "english-self-funded" ? (
          <Row>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>DATE OF STUDENT PAYMENT:</LabelText>
                <SubText> &nbsp;{props.students.degree.en_name}</SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Arrival Date:</LabelText>{" "}
                <SubText>&nbsp;{props.students.cgpa || "N/A"}</SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Location:</LabelText>{" "}
                <SubText> &nbsp;{props.students.destination.en_name}</SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText style={{}}>No. of weeks:</LabelText>{" "}
                <SubText style={{ textTransform: "lowercase" }}>
                  &nbsp;{props.students.email}
                </SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Nationality:</LabelText>{" "}
                <SubText>
                  &nbsp;{props.students.nationality.english_name}
                </SubText>
              </div>
            </Col>

            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Residence:</LabelText>{" "}
                <SubText>
                  &nbsp;{props.students.residence.english_name || "N/A"}
                </SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Subjects:</LabelText>{" "}
                <SubText>&nbsp;{props.students.subjects || "N/A"}</SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>IELTS Score:</LabelText>{" "}
                <SubText>
                  &nbsp;
                  {props.students.engLevel !== "N/A"
                    ? "Not Taken"
                    : props.students.iltesScore
                      ? props.students.iltesScore
                      : "N/A"}
                </SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Sponsored By:</LabelText>{" "}
                <SubText>
                  &nbsp;
                  {props.students.sponsored === "true"
                    ? props.students.sponsoredBy
                    : props.students.sponsored === "false"
                      ? "Not Sponsored"
                      : props.students.sponsored === "null"
                        ? "N/A"
                        : ""}
                </SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>English Level:</LabelText>{" "}
                <SubText>&nbsp;{props.students.engLevel || "N/A"}</SubText>
              </div>
            </Col>
            {props.students.countriesTraveled ? (
              <Col md={4} style={{ marginBottom: 40 }}>
                <div className="d-flex flex-row ">
                  <LabelText>Country Visited:</LabelText>{" "}
                  <SubText>
                    &nbsp;{props.students.countriesTraveled.toString() || "N/A"}
                  </SubText>
                </div>
              </Col>
            ) : null}
            {
              <Col md={4} style={{ marginBottom: 40 }}>
                <div className="d-flex flex-row ">
                  <LabelText>Income above $50000:</LabelText>{" "}
                  <SubText>
                    &nbsp;
                    {props.students.parentsIncome === "true"
                      ? "Yes"
                      : props.students.parentsIncome === "false"
                        ? "No"
                        : "N/A"}
                  </SubText>
                </div>
              </Col>
            }
            <Col md={12} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Universities:</LabelText>{" "}
                {!props.students.selectedUniversity.includes("not sure") ? (
                  <UniversityCon>
                    {props.students.selectedUniversity.map((a: any) => (
                      <li>{a.name}</li>
                    ))}
                  </UniversityCon>
                ) : (
                  <SubText>&nbsp; N/A</SubText>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Degree:</LabelText>
                <SubText> &nbsp;{props.students.degree.en_name}</SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>GPA:</LabelText>{" "}
                <SubText>&nbsp;{props.students.cgpa || "N/A"}</SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Destination:</LabelText>{" "}
                <SubText> &nbsp;{props.students.destination.en_name}</SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText style={{}}>Email:</LabelText>{" "}
                <SubText style={{ textTransform: "lowercase" }}>
                  &nbsp;{props.students.email}
                </SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Nationality:</LabelText>{" "}
                <SubText>
                  &nbsp;{props.students.nationality.english_name}
                </SubText>
              </div>
            </Col>

            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Residence:</LabelText>{" "}
                <SubText>
                  &nbsp;
                  {props.students.residence
                    ? props.students.residence.english_name
                    : "N/A"}
                </SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Subjects:</LabelText>{" "}
                <SubText>&nbsp;{props.students.subjects || "N/A"}</SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>IELTS Score:</LabelText>{" "}
                <SubText>
                  &nbsp;
                  {props.students.engLevel !== "N/A"
                    ? "Not Taken"
                    : props.students.iltesScore
                      ? props.students.iltesScore
                      : "N/A"}
                </SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Sponsored By:</LabelText>{" "}
                <SubText>
                  &nbsp;
                  {props.students.sponsored === "true"
                    ? props.students.sponsoredBy
                    : props.students.sponsored === "false"
                      ? "Not Sponsored"
                      : props.students.sponsored === "null"
                        ? "N/A"
                        : ""}
                </SubText>
              </div>
            </Col>
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>English Level:</LabelText>{" "}
                <SubText>&nbsp;{props.students.engLevel || "N/A"}</SubText>
              </div>
            </Col>
            {props.students.countriesTraveled ? (
              <Col md={4} style={{ marginBottom: 40 }}>
                <div className="d-flex flex-row ">
                  <LabelText>Country Visited:</LabelText>{" "}
                  <SubText>
                    &nbsp;{props.students.countriesTraveled.toString() || "N/A"}
                  </SubText>
                </div>
              </Col>
            ) : null}
            {
              <Col md={4} style={{ marginBottom: 40 }}>
                <div className="d-flex flex-row ">
                  <LabelText>Income above $50000:</LabelText>{" "}
                  <SubText>
                    &nbsp;
                    {props.students.parentsIncome === "true"
                      ? "Yes"
                      : props.students.parentsIncome === "false"
                        ? "No"
                        : "N/A"}
                  </SubText>
                </div>
              </Col>
            }
            <Col md={4} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Previous School:</LabelText>{" "}
                <SubText>
                  &nbsp;
                  {props.students.previousSchool
                    ? props.students.previousSchool
                    : "N/A"}
                </SubText>
              </div>
            </Col>

            <Col md={12} style={{ marginBottom: 40 }}>
              <div className="d-flex flex-row ">
                <LabelText>Universities:</LabelText>{" "}
                {!props.students.selectedUniversity.includes("not sure") ? (
                  <UniversityCon>
                    {props.students.selectedUniversity.map((a: any) => (
                      <li>{a.name}</li>
                    ))}
                  </UniversityCon>
                ) : (
                  <SubText>&nbsp; N/A</SubText>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent
          btnType="dark"
          loading={false}
          label="Close"
          onClick={() => props.onHide()}
        />
      </Modal.Footer>
    </Modal>
  );
};
