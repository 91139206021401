import * as React from "react";
// import { Switch } from "./styled";
import "./style.scss";

interface IToggleButtonProps {
  label: string;
  onChange: Function;
  checked: boolean;
  style?: object;
}

export const ToggleButton: React.FunctionComponent<IToggleButtonProps> = (
  props
) => {
  return (
    <>
      {/* {props.label}{" "} */}
      <div className="toggle-switch" style={props.style}>
        <input
          type="checkbox"
          className="checkbox"
          name={props.label}
          id={props.label}
          onChange={async () => await props.onChange()}
          checked={props.checked}
        />
        <label className="label" htmlFor={props.label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </>
  );
};
