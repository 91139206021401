/* eslint-disable no-empty-pattern */
import React, { useState } from "react";
import { Card } from "./styled/login";
import { ButtonComponent, PasswordInput } from "../Components";
import { Formik, Form, FormikHelpers } from "formik";
import { Logo } from "../Assets";
import { resetPassword } from "../Services/Function";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

type Props = {};

export function ResetPassword({}: Props) {
  const [loading, setloading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  return (
    <div
      className="row align-items-center justify-content-center"
      style={{ height: "100vh", background: "#7F56D9" }}
    >
      <Card>
        <>
          <Logo color="rgb(127, 86, 217)" />
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validate={(values) => {
              const errors: any = {};
              if (password.length < 8) {
                errors.password = "Password must be at least 8 characters";
              }
              if (!/\d/.test(password)) {
                errors.password = "Password must contain at least one digit";
              }
              if (!/[^A-Za-z0-9]/.test(password)) {
                errors.password =
                  "Password must contain at least one special character";
              }
              if (password !== confirmPassword) {
                errors.confirmPassword = "Passwords do not match";
              }
              return errors;
            }}
            onSubmit={async (
              values: any,
              { setSubmitting }: FormikHelpers<any>
            ) => {
              setloading(true);
              try {
                await resetPassword({
                  password: password,
                  token: searchParams.get("token"),
                });
                alert("Password updated");
                navigate("/");
                setloading(false);
              } catch (error) {
                console.log(error);
                alert(error);
              }
            }}
          >
            {({ values, handleChange, errors, touched }) => (
              <Form style={{ width: "70%" }} className="d-flex flex-column">
                <PasswordInput
                  id="password"
                  name="password"
                  label="Password"
                  value={password}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                  }}
                  error={errors.password && touched.password}
                  errorMessage={errors.password}
                />

                <PasswordInput
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                  error={errors.confirmPassword}
                  errorMessage={errors.confirmPassword}
                />

                <ButtonComponent
                  btnType="dark"
                  loading={loading}
                  label="Reset"
                />
              </Form>
            )}
          </Formik>
        </>
      </Card>
    </div>
  );
}
