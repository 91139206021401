import React from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { ProgressBar, Row, Col } from "react-bootstrap";
import { Label } from "./FormInput/styled";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const ChartCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const InfoCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
  color: #1c1c1c;
`;

const Value = styled.div`
  font-weight: bold;
  font-size: 32px;
  color: #1e2432;
`;

const Percentage = styled.div`
  font-size: 14px;
`;

export type EmojiContainerProps = {
  background?: string;
};
const EmojiContainer = styled.div<EmojiContainerProps>(
  ({ background }) => `
  background: ${background};
  width: 30px;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
`
);

const CardDataCon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
  padding: 16px;
  gap: 24px;
  background: #ffffff;
  border: 1px solid #f2f6fb;
  border-radius: 12px;
`;

type Props = {
  totalAmountEngFunded?: number;
  bookingSum?: number;
  totalWeek?: number;
  lastMonthBookingSum: number;
  lastMonthTotalWeek: number;
  lastMonthTotalAmountEngFunded: number;
  graphData: any;
  lastYearEngselfFundedData: any;
};

const calculatePercentageChange = (
  current: number,
  previous: number
): string => {
  if (previous === 0 || current === 0) return "N/A"; // Handle division by zero
  const change = ((current - previous) / previous) * 100;
  const formattedChange = change.toFixed(2);
  return `${formattedChange}%`;
};

export const EnglishSelfFunded: React.FunctionComponent<Props> = (props) => {
  const {
    totalAmountEngFunded = 0,
    bookingSum = 0,
    totalWeek = 0,
    lastMonthBookingSum = 0,
    lastMonthTotalWeek = 0,
    lastMonthTotalAmountEngFunded = 0,
    graphData,
    lastYearEngselfFundedData,
  } = props;

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Last Year",
        data: lastYearEngselfFundedData,
        backgroundColor: "#FD3E60",
        barThickness: 20,
        maxBarThickness: 20,
        borderWidth: 1,
      },
      {
        label: `${new Date().getFullYear()}`,
        data: graphData,
        backgroundColor: "rgba(104, 88, 244, 0.8)",
        barThickness: 20,
        maxBarThickness: 20,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const bookingSumChange = calculatePercentageChange(
    bookingSum,
    lastMonthBookingSum
  );
  const totalWeekChange = calculatePercentageChange(
    totalWeek,
    lastMonthTotalWeek
  );
  const totalAmountChange = calculatePercentageChange(
    totalAmountEngFunded,
    lastMonthTotalAmountEngFunded
  );

  const currentMonthIndex = new Date().getMonth();

  return (
    <>
      <Row className="mb-4">
        <Col className="res-mb-4" xs={12} md={8}>
          <ChartCard>
            <Title>English Self-Funded</Title>
            <ProgressBar
              animated
              label={""}
              now={(graphData[currentMonthIndex] / 8000) * 100}
            />
            <Label
              style={{ marginTop: 10 }}
            >{`Achieved ${graphData[currentMonthIndex]} of 8000 in ${new Date().toLocaleString(
              "default",
              {
                month: "long",
              }
            )}`}</Label>
            <Bar data={data} options={options} />
          </ChartCard>
        </Col>
        <Col xs={12} md={4}>
          <Row className="gy-4">
            <Col xs={12}>
              <InfoCard>
                <div className="d-flex justify-content-between">
                  <Title>Total Students</Title>
                  <EmojiContainer background={"#3DD3A436"}>👩🏼‍🎓</EmojiContainer>
                </div>
                <CardDataCon>
                  <Value>{bookingSum}</Value>
                  {/* <Percentage>
                    <b style={{ fontSize: 20 }}>{bookingSumChange}</b>
                    <br /> vs Last Month
                  </Percentage> */}
                </CardDataCon>
              </InfoCard>
            </Col>
            <Col xs={12}>
              <InfoCard>
                <div className="d-flex justify-content-between">
                  <Title>Total Weeks</Title>
                  <EmojiContainer background={"#2E9DF436"}>🗓</EmojiContainer>
                </div>
                <CardDataCon>
                  <Value>{totalWeek}</Value>
                  {/* <Percentage>
                    <b style={{ fontSize: 20 }}>{totalWeekChange}</b>
                    <br /> vs Last Month
                  </Percentage> */}
                </CardDataCon>
              </InfoCard>
            </Col>
            <Col xs={12}>
              <InfoCard>
                <div className="d-flex justify-content-between">
                  <Title>Total Amount</Title>
                  <EmojiContainer background={"#BEA1FF36"}>🤑</EmojiContainer>
                </div>
                <CardDataCon>
                  <Value>{totalAmountEngFunded}KD</Value>
                  {/* <Percentage>
                    <b style={{ fontSize: 20 }}>{totalAmountChange}</b>
                    <br /> vs Last Month
                  </Percentage> */}
                </CardDataCon>
              </InfoCard>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
