/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
import React, { Suspense, useRef, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { addDays } from "date-fns";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  getApplicationData,
  getAppliedStudenntPerMonth,
  getAppliedStudentsPerCounselor,
  getDashboardData,
  getDashboardDataLeads,
  getDashboardDataStudents,
  getLeaderBoard,
  getPhaseCount,
  getQuotes,
  getUsersWithoutPagination,
} from "../Services/Function";
import { FilterCon } from "./styled/dashboard";

import { DashboardTab, DashboardTab1 } from "../Assets";

import {
  DateFilter,
  EnglishSelfFunded,
  LeadsComponent,
  Loader,
  LoadingAnimation,
  NumberInputModal,
  QuotesComponent,
  SalesComponent,
  SalesComponent2,
  StudentComponent,
  TopSchoolTable,
} from "../Components";
import { Bar } from "react-chartjs-2";
import ProgressBar from "react-bootstrap/ProgressBar";

import Chart from "chart.js/auto";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Label } from "../Components/FormInput/styled";
import { DashboardTab2 } from "../Assets/Icons/DashboardTab2";
import styled from "styled-components";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Props = {};

const ChartCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ChartTitle = styled.h2`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
  color: #1c1c1c;
`;

export function Dashboard({}: Props) {
  const auth = useSelector((state: any) => state.auth);
  var authToken = localStorage.getItem("authToken");
  const [totalResultsUnqualified, settotalResultsUnqualified] =
    React.useState(0);

  const [totalResultsQualified, setotalResultsQualified] = React.useState(0);

  const [studentApplied, setstudentApplied] = useState(0);
  const [studentNew, setstudentNew] = useState(0);
  const [studentWa, setstudentWa] = useState(0);
  const [studentClosed, setstudentClosed] = useState(0);
  const [studentsUlearn, setstudentsUlearn] = useState<any>(0);
  const [studentUapply, setstudentUapply] = useState<any>(0);
  const [DailyQuote, setDailyQuote] = useState("");
  const [top5Countries, settop5Countries] = useState([]);
  const [top5Applied, settop5Applied] = useState([]);
  const [top5Degree, settop5Degree] = useState([]);
  const [thisYearApplied, setthisYearApplied] = useState([]);
  const [loading, setloading] = useState(false);
  const [thisYear, setthisYear] = useState([]);
  const [studentsOthers, setstudentsOthers] = useState<any>(0);
  const [studentsUk, setstudentsUk] = useState<any>(0);
  const [show, setShow] = useState(false);
  const [quotesArray, setquotesArray] = useState<any>([]);
  const [ofcFeesLeaders, setofcFeesLeaders] = useState([]);
  const [users, setusers] = useState<any>([]);
  const [stdVisaLeaders, setstdVisaLeaders] = useState<any>([]);
  const [ieltsLeader, setieltsLeader] = useState<any>([]);
  const [selfFundedLeader, setengSelfFundedLeader] = useState<any>([]);
  const [salesPersonData, setsalesPersonData] = useState<any>([]);
  const [accountManagerData, setaccountManagerData] = useState<any>([]);
  const [operationData, setoperationData] = useState<any>([]);
  const [previousThreeMonths, setpreviousThreeMonths] = useState<any>([]);
  const [selectedMonth, setselectedMonth] = useState("");
  const [top5AppliedNationality, settop5AppliedNationality] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState("fees");
  const [totalAmountSum, settotalAmountSum] = useState(0);
  const [bookingsSum, setbookingsSum] = useState(0);
  const [totalAmountEngFunded, settotalAmountEngFunded] = useState<any>(0);
  const [engFundedBookingSum, setengFundedBookingSum] = useState(0);
  const [totalWeeks, settotalWeeks] = useState(0);
  const [lastMonthBookingSum, setlastMonthBookingSum] = useState(0);
  const [lastMonthTotalWeek, setlastMonthTotalWeek] = useState(0);
  const [lastMonthTotalAmountEngFunded, setlastMonthTotalAmountEngFunded] =
    useState(0);
  const [topSchools, settopSchools] = useState([]);
  const [topCities, settopCities] = useState([]);
  const [phaseCount, setphaseCount] = useState([]);
  const [topUniversities, settopUniversities] = useState([]);
  const [topUniversitiesEnrolled, settopUniversitiesEnrolled] = useState([]);

  const [topTypes, settopTypes] = useState([]);
  const [topTests, settopTests] = useState([]);
  const [enrolledApplicationByMonth, setenrolledApplicationByMonth] =
    useState<any>([]);
  const [applicationByMonth, setapplicationByMonth] = useState<any>([]);
  const [totalIelts, settotalIelts] = useState(0);
  const [totalStudentvisa, settotalStudentvisa] = useState(0);
  const [studentVisaSum, setstudentVisaSum] = useState(0);
  const [intakeYear, setintakeYear] = useState("");
  const [intakeMonth, setintakeMonth] = useState("");
  const today = new Date();
  const [appliedStudentsPerCounselor, setappliedStudentsPerCounselor] =
    useState("");
  const [startDate, setStartDate] = useState(addDays(today, -30));
  const [endDate, setEndDate] = useState(today);
  const [intakeYearEnrolled, setintakeYearEnrolled] = useState("");
  const [intakeMonthEnrolled, setintakeMonthEnrolled] = useState("");
  const [dashboardDataStudent, setdashboardDataStudent] = useState<any>([]);
  const getLeadersByMonth = async (startDate: any, endDate: any) => {
    const ofcLeader = await getLeaderBoard("office-fees", startDate, endDate);

    let totalAmountSum = 0;
    let bookingsSum = 0;
    ofcLeader.forEach((item: any) => {
      totalAmountSum += item.totalAmount;
      bookingsSum += item.bookings;
    });
    setbookingsSum(bookingsSum);
    settotalAmountSum(totalAmountSum);

    const ieltsLeader = await getLeaderBoard(
      "ielts-booking",
      startDate,
      endDate
    );
    const stdVisa = await getLeaderBoard("student-visa", startDate, endDate);
    const selfFunded = await getLeaderBoard(
      "english-self-funded",
      startDate,
      endDate
    );

    let stdVisaAmountSum = 0,
      stdVisabookingsSum = 0;

    stdVisa
      .filter((z: any) => z.incharge)
      .forEach((item: any) => {
        stdVisaAmountSum += item.totalAmount;
        stdVisabookingsSum += item.bookings;
      });

    settotalStudentvisa(stdVisaAmountSum);
    setstudentVisaSum(stdVisabookingsSum);

    let ieltsLeaderbookingsSum = 0;

    ieltsLeader.forEach((item: any) => {
      ieltsLeaderbookingsSum += item.bookings;
    });

    settotalIelts(ieltsLeaderbookingsSum);

    setengSelfFundedLeader(selfFunded);
    setstdVisaLeaders(stdVisa);
    setieltsLeader(ieltsLeader);
    setofcFeesLeaders(ofcLeader);

    const leaders1 = transformDataForRole(selfFunded, "salesPerson");
    const leaders2 = transformDataForRole(selfFunded, "accountManager");
    const leaders3 = transformDataForRole(selfFunded, "operation");

    setsalesPersonData(mergeData(leaders1));
    setaccountManagerData(mergeData(leaders2));
    setoperationData(mergeData(leaders3));
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        setloading(true);
        const quotes: any = await getQuotes({
          limit: 100,
          page: 1,
          visibility: true,
        });
        filterData(startDate, endDate);
        const users = await getUsersWithoutPagination();
        setusers(users);

        const applied = await getAppliedStudentsPerCounselor({
          startDate,
          endDate,
        });

        setappliedStudentsPerCounselor(applied);
        setquotesArray(quotes?.results);

        displayDailyMessage(quotes?.results);

        const thisYearApplied = await getAppliedStudenntPerMonth();
        setthisYearApplied(thisYearApplied);

        if (chartContainer.current) {
          // Set the chart height based on the container size
          setChartHeight(350);
        }
        await getLeadersByMonth(startDate, endDate);

        const res: any = await getPhaseCount({
          startDate: startDate,
          endDate: endDate,
        });

        setphaseCount(res);
        setloading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setloading(false);
      }
    }

    fetchData();
  }, []);

  const mergeData = (data: any) => {
    const groupedData: { [key: string]: any } = {};

    data.forEach((entry: any) => {
      const { accountManager, salesPerson, operation } = entry.tag;
      const key = `${accountManager}-${salesPerson}-${operation}`;

      if (groupedData[key]) {
        groupedData[key].bookings += entry.bookings;
        groupedData[key].totalAmount += entry.totalAmount;
      } else {
        groupedData[key] = { ...entry };
      }
    });

    const result = Object.values(groupedData).sort(
      (a: any, b: any) => b.bookings - a.bookings
    );

    return result;
  };

  function transformDataForRole(selfFunded: any, role: any) {
    return selfFunded.map((entry: any) => {
      const transformedEntry: any = {
        totalAmount: entry.totalAmount,
        bookings: entry.bookings,
        tag: {},
      };

      const roleId = entry[role].id;
      transformedEntry.tag[role] = roleId;

      return transformedEntry;
    });
  }
  const thisYearOfficeFees = thisYear
    .filter((item: any) => {
      const currentYear = new Date().getFullYear();
      const itemYear = item.year; // Ensure `date` is a valid field in your data
      return itemYear === currentYear;
    })
    .sort((a: any, b: any) => a.month - b.month)
    .filter((z: any) => z.feeType === "office-fees")
    .map((a: any) => a.totalAmount);

  const lastYearOfficeFees = thisYear
    .filter((item: any) => {
      const currentYear = new Date().getFullYear() - 1;
      const itemYear = item.year; // Ensure `date` is a valid field in your data
      return itemYear === currentYear;
    })
    .sort((a: any, b: any) => a.month - b.month)
    .filter((z: any) => z.feeType === "office-fees")
    .map((a: any) => a.totalAmount);

  const thisYearData = thisYearOfficeFees;

  const thisYearTarget = [
    1300, 1000, 1200, 800, 1800, 900, 900, 600, 200, 200, 3000, 2100,
  ];

  const thisYearAppliedData = [
    ...thisYearApplied
      .filter((item: any) => {
        const currentYear = new Date().getFullYear();
        const itemYear = item.year; // Ensure `date` is a valid field in your data
        return itemYear === currentYear;
      })
      .sort((a: any, b: any) => a.month - b.month)
      .map((a: any) => a.number),
  ];

  const lastYearAppliedData = [
    ...thisYearApplied
      .filter((item: any) => {
        const currentYear = new Date().getFullYear() - 1;
        const itemYear = item.year; // Ensure `date` is a valid field in your data
        return itemYear === currentYear;
      })
      .sort((a: any, b: any) => a.month - b.month)
      .map((a: any) => a.number),
  ];

  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Last Year",
        data: lastYearOfficeFees,
        backgroundColor: "#FD3E60",
        barThickness: 20,
        maxBarThickness: 20,
        borderWidth: 1,
      },
      {
        label: "This Year",
        data: thisYearData,
        backgroundColor: "#7F56D9", // Example color
        borderColor: "#7F56D9", // Example color
        borderWidth: 1,
      },
    ],
  };

  // Bar chart options
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        barPercentage: 0.5, // Adjust the width of the bars
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Students Applied:",
      },
      scales: {
        y: {
          min: 10, // Set the minimum value for the y-axis
        },
      },
    },
  };

  const dataStudent = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Last Year",
        data: lastYearAppliedData,
        backgroundColor: "#FD3E60", // Example color
        borderColor: "#FD3E60", // Example color
        borderWidth: 1,
      },
      {
        label: "This Year",
        // data: [67, 43, 68],
        data: thisYearAppliedData,
        backgroundColor: "#7F56D9", // Example color
        borderColor: "#7F56D9", // Example color
        borderWidth: 1,
      },
    ],
  };

  // Bar chart options
  const optionsStudent: any = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        barPercentage: 0.5, // Adjust the width of the bars
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Students Applied:",
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Start y-axis at zero
        suggestedMax: 5, // Suggest a max value of 10
        ticks: {
          stepSize: 1, // Ensure the y-axis shows whole numbers only
        },
      },
    },
  };

  function getRandomQuote(qArray: any) {
    const today = new Date().getDate();
    const index = (today - 1) % qArray.length;
    setDailyQuote(qArray[index].text);
  }

  // Function to display a different message based on the current day
  function displayDailyMessage(qArray: any) {
    const today = new Date().getDay();
    switch (today) {
      case 0:
        return getRandomQuote(qArray);
      case 1:
        return getRandomQuote(qArray);
      case 2:
        return getRandomQuote(qArray);
      case 3:
        return getRandomQuote(qArray);
      case 4: // Thursday
        return getRandomQuote(qArray);
      case 5: // Friday
        return setDailyQuote("Have a beautiful weekend everone! ❤️");
      case 6: // Saturday
        return setDailyQuote("Have a beautiful weekend everone! ❤️");
      default:
        return "";
    }
  }
  const chartContainer = useRef(null);
  const [chartHeight, setChartHeight] = useState(300); // Default height

  const reload = async (startDate: any, endDate: any) => {
    try {
      setloading(true);
      await getLeadersByMonth(startDate, endDate);
      await filterData(startDate, endDate);
      const res: any = await getPhaseCount({
        startDate: startDate,
        endDate: endDate,
      });
      const applied = await getAppliedStudentsPerCounselor({
        startDate,
        endDate,
      });

      setappliedStudentsPerCounselor(applied);
      setphaseCount(res);
      setloading(false);
    } catch (error) {
      setloading(false);
      alert(error);
    }
  };

  if (!auth.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }

  const date = new Date();
  const monthIndex = date.getMonth();
  const feeGoal = thisYearTarget.filter(
    (a: any, key: any) => key === monthIndex
  )[0];
  const feeGoalStudent = [
    60, 50, 50, 54, 80, 70, 80, 30, 20, 60, 50, 70,
  ].filter((a: any, key: any) => key === monthIndex)[0];

  const achived = thisYearData.filter(
    (a: any, key: any) => key === monthIndex
  )[0];

  const achivedStudent = thisYearAppliedData.filter(
    (a: any, key: any) => key === monthIndex
  )[0];

  const appliedStudentGoals = [
    60, 50, 50, 54, 80, 70, 80, 30, 20, 60, 50, 70,
  ].filter((a: any, key: any) => key === monthIndex)[0];

  const filterData = async (startDate: any, endDate: any) => {
    const dashboardData = await getDashboardData({
      startDate: startDate,
      endDate: endDate,
    });

    const app = await getDashboardDataStudents({
      startDate: startDate,
      endDate: endDate,
    });

    const applicationData = await getApplicationData({
      startDate: startDate,
      endDate: endDate,
      intakeYear: intakeYear,
      intakeMonth: intakeMonth,
    });

    const dashboardDataLeads = await getDashboardDataLeads({
      startDate: startDate,
      endDate: endDate,
    });

    settopUniversities(
      applicationData?.applicationsData.topUniversitiesByApplications
    );
    settopUniversitiesEnrolled(
      applicationData.applicationsData.topEnrolledUniversities
    );

    settotalAmountEngFunded(dashboardData.selfFunded.totalAmount);
    setengFundedBookingSum(dashboardData.selfFunded.totalBookings);
    settotalWeeks(dashboardData.selfFunded.totalWeeks);

    setdashboardDataStudent(app);
    settopTests(dashboardData.topTests);
    settopTypes(dashboardData.topTypes);
    settopSchools(dashboardData.topSchools);
    settopCities(dashboardData.topCities);
    setthisYear(dashboardData.amounts);
    setenrolledApplicationByMonth(
      applicationData.applicationsData.enrolledApplicationsCountByMonth
    );
    setapplicationByMonth(
      applicationData.applicationsData.applicationsCountByMonth
    );
    settop5AppliedNationality(top5AppliedNationality);
    setstudentsOthers(dashboardDataLeads.studentsOthersCount);
    setstudentsUk(dashboardDataLeads.studentsUkCount);
    setstudentClosed(dashboardDataLeads.studentsClosedCount);
    setstudentNew(dashboardDataLeads.studentsNewCount);
    setstudentWa(dashboardDataLeads.studentsWaCount);
    setstudentApplied(dashboardDataLeads.studentsAppliedCount);
    setstudentUapply(dashboardDataLeads.studentsUapplyCount);
    setstudentsUlearn(dashboardDataLeads.studentsUlearnCount);
    settop5Degree(dashboardDataLeads.top5ByDegree);
    settop5Applied(dashboardDataLeads.top5ByCountry);
    settop5Countries(dashboardDataLeads.top5ByCountry);
    settotalResultsUnqualified(dashboardDataLeads.unqualifiedLeads);
    setotalResultsQualified(dashboardDataLeads.qualifiedLeads);
    setloading(false);
  };

  const currentYearData = thisYear
    .filter((item: any) => {
      const currentYear = new Date().getFullYear();
      const itemYear = item.year; // Ensure `date` is a valid field in your data
      return itemYear === currentYear;
    })
    .sort((a: any, b: any) => a.month - b.month)
    .filter((z: any) => z.feeType === "english-self-funded")
    .map((a: any) => a.totalAmount);

  const lastYearEngselfFundedData = thisYear
    .filter((item: any) => {
      const lastYear = 2024;
      const itemYear = item.year; // Ensure `date` is a valid field in your data
      return itemYear === lastYear;
    })
    .sort((a: any, b: any) => a.month - b.month)
    .filter((z: any) => z.feeType === "english-self-funded")
    .map((a: any) => a.totalAmount);

  return (
    <Suspense fallback={<Loader />}>
      {loading ? (
        <Container style={{ marginTop: 0 }}>
          {" "}
          <LoadingAnimation height="100%" width="100%" />
        </Container>
      ) : (
        <Container>
          <div style={{ margin: "30px 0 10px 0" }}>
            <Row>
              <Col md={12}>
                <QuotesComponent dailyQuote={DailyQuote} />
              </Col>
            </Row>
          </div>
          <div className="d-flex flex-column" style={{ position: "relative" }}>
            <div>
              <Tabs
                className="dashboard-tabs"
                defaultActiveKey="fees"
                id="uncontrolled-tab-example"
                onSelect={(k: any) => setSelectedTab(k)}
                style={{ position: "relative" }}
              >
                <Tab
                  eventKey="fees"
                  title={
                    <div>
                      <DashboardTab1
                        style={{ marginRight: 16 }}
                        selected={selectedTab === "fees"}
                      />
                      Fees
                    </div>
                  }
                >
                  <EnglishSelfFunded
                    totalAmountEngFunded={totalAmountEngFunded}
                    bookingSum={engFundedBookingSum}
                    totalWeek={totalWeeks}
                    lastMonthBookingSum={lastMonthBookingSum}
                    lastMonthTotalWeek={lastMonthTotalWeek}
                    lastMonthTotalAmountEngFunded={
                      lastMonthTotalAmountEngFunded
                    }
                    graphData={[...currentYearData]}
                    lastYearEngselfFundedData={[...lastYearEngselfFundedData]}
                  />
                  <Row style={{ marginTop: 20 }}>
                    <Col md={4}>
                      <SalesComponent
                        th={["Manager", "N° B", "T. Amount"]}
                        header="Sales"
                        icon={"💸"}
                        data={salesPersonData}
                        users={users}
                      />
                    </Col>
                    <Col md={4}>
                      <SalesComponent
                        th={["Manager", "N° B", "T. Amount"]}
                        header="Account Manager"
                        icon={"🧑🏻‍💻"}
                        data={accountManagerData}
                        users={users}
                      />
                    </Col>
                    <Col md={4}>
                      <SalesComponent
                        th={["Manager", "N° B", "T. Amount"]}
                        header="Operations"
                        icon={"⚙️"}
                        data={operationData}
                        users={users}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }} className="mb-4">
                    <Col md={6}>
                      <TopSchoolTable
                        data={topSchools.sort(
                          (a: any, b: any) => b.count - a.count
                        )}
                        school={true}
                        topTypes={false}
                      />
                    </Col>
                    <Col md={6}>
                      <TopSchoolTable
                        data={topCities}
                        school={false}
                        cities={true}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col md={6}>
                      <ChartCard>
                        <ChartTitle>Office Fees</ChartTitle>
                        <div style={{ position: "relative" }}>
                          <ProgressBar
                            animated
                            label={""}
                            now={(achived / feeGoal) * 100}
                          />
                        </div>
                        <Label
                          style={{ marginTop: 10 }}
                        >{`Achieved ${achived} of ${feeGoal} in ${date.toLocaleString(
                          "default",
                          { month: "long" }
                        )}`}</Label>
                        <div className="chart-container">
                          <Bar data={data} options={options} />
                        </div>
                      </ChartCard>
                    </Col>
                    <Col lg={6}>
                      <SalesComponent
                        th={["Manager", "N° B", "T. Amount"]}
                        header="Office Fees"
                        icon={"💸"}
                        data={ofcFeesLeaders}
                        users={users}
                        totalAmountSum={totalAmountSum}
                        bookingsSum={bookingsSum}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TopSchoolTable
                        data={topTypes.sort(
                          (a: any, b: any) => b.count - a.count
                        )}
                        topTypes={true}
                        school={false}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col md={12}>{/* <TopSchoolTable /> */}</Col>
                  </Row>

                  <Row className="mb-4">
                    <Col lg={6}>
                      <SalesComponent2
                        th2={["Type of Test", "N° Bookings"]}
                        color="#FDBC3E36"
                        th={["Manager", "N° Bookings"]}
                        header="IELTS Booking"
                        icon={"👍🏻"}
                        data={ieltsLeader.sort(
                          (a: any, b: any) => b.bookings - a.bookings
                        )}
                        data2={topTests}
                        users={users}
                        bookingsSum={totalIelts}
                      />
                    </Col>
                    <Col lg={6}>
                      <SalesComponent2
                        color="#BEA1FF33"
                        th2={["Checked By", "N° B", "T. Amount"]}
                        th={["Manager", "N° B", "T. Amount"]}
                        header="Visa Fees"
                        icon={"📄"}
                        data={stdVisaLeaders.filter((a: any) => a.incharge)}
                        data2={stdVisaLeaders.filter((a: any) => a.checkedBy)}
                        users={users}
                        bookingsSum={studentVisaSum}
                        totalAmountSum={totalStudentvisa}
                      />
                      {/* <SalesComponent2
                        th={["Manager", "N° B", "T. Amount"]}
                        header="Visa Fees"
                        icon={"🧑🏻‍💻"}
                        data={stdVisaLeaders}
                        users={users}
                      /> */}
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="leads"
                  title={
                    <div>
                      <DashboardTab
                        style={{ marginRight: 16 }}
                        selected={selectedTab === "leads"}
                      />
                      Leads
                    </div>
                  }
                >
                  <LeadsComponent
                    qualified={totalResultsQualified}
                    notQualified={totalResultsUnqualified}
                    studentUapply={studentUapply}
                    studentUlearn={studentsUlearn}
                    studentUk={studentsUk}
                    studentsOthers={studentsOthers}
                    studentApplied={studentApplied}
                    studentClosed={studentClosed}
                    studentWa={studentWa}
                    studentNew={studentNew}
                    top5Countries={top5Countries}
                    top5Degree={top5Degree}
                  />
                </Tab>
                <Tab
                  eventKey="students"
                  title={
                    <div>
                      <DashboardTab2
                        style={{ marginRight: 16 }}
                        selected={selectedTab === "students"}
                      />
                      Students
                    </div>
                  }
                >
                  {selectedTab === "students" ? (
                    <StudentComponent
                      studentApplied={thisYearAppliedData.reduce(
                        (accumulator: any, currentValue: any) =>
                          accumulator + currentValue,
                        0
                      )}
                      appliedStudentsPerCounselor={appliedStudentsPerCounselor}
                      setenrolledApplicationByMonth={
                        setenrolledApplicationByMonth
                      }
                      dashboardDataStudent={dashboardDataStudent}
                      setapplicationByMonth={setapplicationByMonth}
                      setintakeYear={setintakeYear}
                      setintakeMonth={setintakeMonth}
                      intakeYearEnrolled={intakeYearEnrolled}
                      setintakeYearEnrolled={setintakeYearEnrolled}
                      setintakeMonthEnrolled={setintakeMonthEnrolled}
                      intakeMonthEnrolled={intakeMonthEnrolled}
                      intakeMonth={intakeMonth}
                      intakeYear={intakeYear}
                      setShow={setShow}
                      applicationByMonth={applicationByMonth.map(
                        (entry: any) => entry.bookingCount
                      )}
                      enrolledApplicationByMonth={enrolledApplicationByMonth.map(
                        (entry: any) => entry.count
                      )}
                      users={users}
                      achivedText={`Achieved ${achivedStudent} of ${appliedStudentGoals} in ${date.toLocaleString(
                        "default",
                        { month: "long" }
                      )}`}
                      progressData={(achivedStudent / feeGoalStudent) * 100}
                      chartHeight={chartHeight}
                      dataStudent={dataStudent}
                      optionsStudent={optionsStudent}
                      chartContainer={chartContainer}
                      top5AppliedNationality={top5AppliedNationality}
                      startDate={startDate}
                      endDate={endDate}
                      phaseCount={phaseCount}
                      topUniversities={topUniversities}
                      topUniversitiesEnrolled={topUniversitiesEnrolled}
                    />
                  ) : null}
                </Tab>
                {/* <Tab
              eventKey="business"
              title={
                <div>
                  <DashboardTab3
                    style={{ marginRight: selectedTab === "business" ? 8 : 0 }}
                    selected={selectedTab === "business"}
                  />
                  Business
                </div>
              }
            >
              
            </Tab> */}
              </Tabs>
            </div>
            <FilterCon>
              <DateFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                reload={reload}
              />
            </FilterCon>
          </div>
        </Container>
      )}
      <NumberInputModal
        userDetails={auth.userDetails}
        users={users}
        show={show}
        reloadData={async () => {
          const end = addDays(endDate, 1);

          const thisYearApplied = await getAppliedStudenntPerMonth();
          const applied = await getAppliedStudentsPerCounselor({
            startDate,
            endDate: end,
          });

          setappliedStudentsPerCounselor(applied);
          setthisYearApplied(thisYearApplied);
        }}
        onHide={() => setShow(false)}
      />
      <br />
      <br />
      <br />
    </Suspense>
  );
}
