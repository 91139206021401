/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import QueryString from "qs";
import * as React from "react";
import { Col, Container, FormControl, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ButtonComponent,
  PaginationComponent,
  SendWA,
  TemplateMessage,
  TextArea,
  ToggleButton,
} from "../Components";
import { State, dataAction } from "../state";
import { Text } from "./styled/whatsapp";
import {
  getExibitionStudents,
  updateExibitionStudent,
} from "../Services/Function";
import { PaginationSection, PaginationSectionText } from "./styled/students";
import { TBody, THead, Table, Td, Th, Tr } from "../Components/ListView/styled";
import { UploadModal } from "../Components/UploadModal";

interface IWhatsAppProps {}

export const WhatsApp: React.FunctionComponent<IWhatsAppProps> = (props) => {
  const [login, setlogin] = React.useState(false);

  const dispatch = useDispatch();
  const { saveWaKey } = bindActionCreators(dataAction, dispatch);
  const waKey = useSelector((state: State) => state.data.waKey);
  const [key, setkey] = React.useState(!waKey ? "" : waKey);
  const [checkWa, setcheckWa] = React.useState(false);
  const [checkWalogs, setcheckWalogs] = React.useState<any>([]);
  const [templateMsg, settemplateMsg] = React.useState(false);

  const [phoneNo, setphoneNo] = React.useState<any>([]);
  const [loading, setloading] = React.useState(false);
  const [numbers, setnumbers] = React.useState<any>([]);
  const [totalResults, settotalResults] = React.useState("");
  const [totalPages, settotalPages] = React.useState<any>("");
  const [page, setpage] = React.useState(1);
  const [modalshow, setmodalshow] = React.useState("");
  const [selectedStudentArr, setselectedStudentArr] = React.useState<any>([]);

  React.useEffect(() => {
    async function fetchData() {
      //   saveWaKey("");
      try {
        const res = await getExibitionStudents({
          sortBy: "createdAt:desc",
          limit: 10,
          page: page,
          status: "not sent",
        });
        setnumbers(res.results);
        settotalResults(res.totalResults);
        settotalPages(res.totalPages);

        // const res = await axios.get(
        //   `https://wa-api-node.onrender.com/instance/info?key=${waKey}`
        // );
      } catch (error) {
        setlogin(false);
        saveWaKey("");
        // window.location.reload();
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const reload = async () => {
    const res = await getExibitionStudents({
      sortBy: "createdAt:desc",
      limit: 10,
      page: page,
      status: "not sent",
    });
    setnumbers(res.results);
    settotalResults(res.totalResults);
    settotalPages(res.totalPages);
    setloading(false);
    setselectedStudentArr([]);
  };

  return (
    <Container>
      <div style={{ marginTop: 40 }} className="d-flex justify-content-between">
        {key ? (
          <ButtonComponent
            onClick={async () => {
              try {
                await axios.delete(
                  `https://wa-api-node.onrender.com/instance/logout?key=${key}`
                );
                await axios.delete(
                  `https://wa-api-node.onrender.com/instance/delete?key=${key}`
                );
                setkey("");
                saveWaKey("");
                setlogin(false);
                window.location.reload();
              } catch (error) {
                setlogin(false);
                saveWaKey("");
                window.location.reload();
              }
            }}
            label={"Logout"}
            loading={!waKey}
            btnType={"light"}
          />
        ) : (
          <ButtonComponent
            onClick={async () => {
              const key = await axios.get(
                `https://wa-api-node.onrender.com/instance/init/?token=UAPPLY_ABROAD_WHATSAPP`
              );
              saveWaKey(key.data.key);
              await setkey(key.data.key);
              window.open(
                `https://wa-api-node.onrender.com/instance/qr?key=${key.data.key}`
              );
              setlogin(true);
            }}
            label={"Scan QR"}
            loading={false}
            btnType={"dark"}
          />
        )}
        {/* {selectedStudentArr.lenght === 10 ? ( */}

        <ButtonComponent
          label={"Send Messages"}
          loading={loading}
          style={{ width: "max-content" }}
          disabled={selectedStudentArr.lenght === 0}
          btnType={"dark"}
          onClick={() => {
            setmodalshow("sendWa");
          }}
          // onClick={async () => {
          //   // setcheckWa(!checkWa);
          //   try {
          //     setloading(true);
          //     const phNos = selectedStudentArr.map(function (num: any) {
          //       return {
          //         phoneNumber: `965${num.phoneNumber}`,
          //         status: num.status,
          //         id: num.id,
          //       };
          //     });
          //     for (let index = 0; index < phNos.length; index++) {
          //       const element = phNos[index];

          //       var data = QueryString.stringify({
          //         id: element.phoneNumber.replace(/[+\s]/g, ""),
          //         message: waText,
          //       });
          //       let config: any;

          //       config = {
          //         method: "post",
          //         url: `https://wa-api-node.onrender.com/message/text?key=${key}`,
          //         headers: {},
          //         data: data,
          //       };

          //       try {
          //         const res = await axios(config);
          //         if (
          //           res.data.status === "Sent" ||
          //           res.status === 200 ||
          //           res.status === 201
          //         ) {
          //           const d = await updateExibitionStudent(element.id, {
          //             status: "Sent",
          //           });
          //         }
          //       } catch (error: any) {
          //         const ress = await updateExibitionStudent(element.id, {
          //           status: "not valid",
          //         });
          //         console.log("notttt senttt", ress);
          //       }
          //     }
          //     const res = await getExibitionStudents({
          //       sortBy: "createdAt:desc",
          //       limit: 10,
          //       page: page,
          //       status: "not sent",
          //     });
          //     setnumbers(res.results);
          //     settotalResults(res.totalResults);
          //     settotalPages(res.totalPages);
          //     setloading(false);
          //     setselectedStudentArr([]);

          //     // setbtnType([]);
          //   } catch (error) {
          //     setloading(false);
          //     //   saveWaKey("");
          //     //   window.location.reload();
          //     console.log(error);
          //   }
          // }}
        />
        {/* <ButtonComponent
          onClick={async () => {
            setmodalshow("upload");
          }}
          style={{ width: "max-content" }}
          label={"Upload contacts"}
          loading={false}
          btnType={"dark"}
        /> */}

        <ButtonComponent
          onClick={async () => {
            setmodalshow("upload");
          }}
          style={{ width: "max-content" }}
          label={"Generate QR"}
          loading={false}
          btnType={"dark"}
        />
        {/* ) : null} */}
      </div>

      <Row>
        <Col md={8}>
          <div className="d-flex flex-column">
            {checkWa && key ? (
              <Formik
                initialValues={{ phoneNos: "" }}
                onSubmit={async function (
                  values: FormikValues,
                  formikHelpers: FormikHelpers<FormikValues>
                ) {
                  try {
                    setloading(true);
                    const phoneNos = values.phoneNos
                      .split(",")
                      .map((a: any) => a.replace(/[^A-Z0-9]/gi, ""));

                    console.log(phoneNos.map((number: any) => 965 + number));
                    // for (let index = 0; index < phoneNos.length; index++) {
                    //   const element = phoneNos[index];
                    //   var config: any = {
                    //     method: "get",
                    //     url: `https://wa-api-node.onrender.com/misc/onwhatsapp?key=${waKey}&id=${element}`,
                    //     headers: {},
                    //   };
                    //   try {
                    //     const res = await axios(config);
                    //     setcheckWalogs((oldMessages: any) => [
                    //       ...oldMessages,
                    //       res.data.data ? `${element} is on whatsapp` : ``,
                    //     ]);
                    //     setphoneNo((oldData: any) => [...oldData, element]);
                    //   } catch (error: any) {
                    //     if (
                    //       error.response.data.message ===
                    //       "Error: no account exists"
                    //     ) {
                    //       setcheckWalogs((oldMessages: any) => [
                    //         ...oldMessages,
                    //         `${element} is not on whatsapp`,
                    //       ]);
                    //     }

                    //     if (
                    //       error.response.data.message === "invalid key supplied"
                    //     ) {
                    //       // saveWaKey("");
                    //       // window.location.reload();
                    //       console.log(error);
                    //     }
                    //   }
                    // }
                    setloading(false);
                    values.phoneNos = "";
                  } catch (error) {
                    setloading(false);
                    console.log(error);
                  }
                }}
              >
                {({ values }) => (
                  <Form
                    className="d-flex flex-column align-items-end"
                    style={{ marginTop: 20 }}
                  >
                    <TextArea
                      id={""}
                      // className="d-flex justify-content-end"
                      name={"phoneNos"}
                      placeholder={"Phone Numbers"}
                      type={"text"}
                      rows="4"
                    />
                    {values.phoneNos && values.phoneNos.length > 0 ? (
                      <ButtonComponent
                        label={"Check"}
                        loading={loading}
                        btnType={"dark"}
                        style={{ marginTop: 20 }}
                      />
                    ) : null}
                  </Form>
                )}
              </Formik>
            ) : (
              <div>
                <Table
                  style={{ marginTop: 30 }}
                  className="table table-scroll "
                >
                  <THead>
                    <Tr>
                      <Th
                        onClick={() => setselectedStudentArr(numbers)}
                        scope="col"
                      >
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${key}`}
                          name={"selectAll"}
                          value={"selectAll"}
                          checked={numbers.every((obj: any) =>
                            selectedStudentArr.some(
                              (item: any) =>
                                item.phoneNumber === obj.phoneNumber &&
                                item.status === obj.status
                            )
                          )}
                          onChange={() => {
                            if (selectedStudentArr.length) {
                              setselectedStudentArr([]);
                            } else {
                              setselectedStudentArr(numbers);
                            }
                          }}
                        />
                        Select All
                      </Th>
                      <Th scope="col">Phone Number</Th>
                      <Th scope="col">Status</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {numbers?.map((a: any) => (
                      <>
                        <Tr key={a.id}>
                          <Td>
                            <input
                              type="checkbox"
                              id={`custom-checkbox-${key}`}
                              name={a.id}
                              value={a.id}
                              checked={selectedStudentArr[a.id]}
                              onChange={() => {
                                if (
                                  selectedStudentArr.some(
                                    (z: { id: string }) => a.id === z.id
                                  )
                                ) {
                                  const updatedData = selectedStudentArr.filter(
                                    (x: { id: string }) => x.id !== a.id
                                  );
                                  setselectedStudentArr(updatedData);
                                } else {
                                  setselectedStudentArr((oldMessages: any) => [
                                    ...oldMessages,
                                    a,
                                  ]);
                                }
                              }}
                            />
                          </Td>
                          <Td>{a.phoneNumber}</Td>
                          <Td>{a.status}</Td>
                        </Tr>
                      </>
                    ))}
                  </TBody>
                </Table>

                <PaginationSection>
                  <PaginationSectionText>
                    {`Showing ${(page - 1) * 10 + 1} to ${
                      page * 10
                    } of ${totalResults} results`}
                  </PaginationSectionText>
                  <PaginationComponent
                    onChangePrev={async () => {
                      setpage(page > 1 ? page - 1 : 1);

                      const data = await getExibitionStudents({
                        sortBy: "createdAt:desc",
                        limit: 10,
                        page: page > 1 ? page - 1 : 1,
                        status: "not sent",
                      });
                      setnumbers(data.results);
                      settotalResults(data.totalResults);
                      settotalPages(data.totalPages);
                    }}
                    totalPage={totalPages}
                    onPageClick={async (x: any) => {
                      setpage(parseInt(x));

                      const data = await getExibitionStudents({
                        sortBy: "createdAt:desc",
                        limit: 10,
                        page: parseInt(x),
                        status: "not sent",
                      });
                      setnumbers(data.results);

                      settotalResults(data.totalResults);
                      settotalPages(data.totalPages);
                    }}
                    page={page}
                    onChange={async () => {
                      setpage(totalPages === page ? page : page + 1);

                      const data = await getExibitionStudents({
                        sortBy: "createdAt:desc",
                        limit: 10,
                        page: page + 1,
                        status: "not sent",
                      });
                      setnumbers(data.results);

                      settotalResults(data.totalResults);
                      settotalPages(data.totalPages);
                    }}
                  />
                </PaginationSection>
              </div>
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            {checkWalogs.length ? (
              <>
                <ButtonComponent
                  label={"Clear"}
                  style={{ width: "45px", height: 30, marginBottom: 10 }}
                  loading={loading}
                  onClick={() => setcheckWalogs([])}
                  btnType={"dark"}
                />
                <Text
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: 900,
                    color: "blue",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(phoneNo).then(
                      function () {
                        alert(" Copying to clipboard was successful!");
                        setphoneNo([]);
                      },
                      function (err) {
                        console.error("Async: Could not copy text: ", err);
                      }
                    );
                  }}
                >
                  Copy Numbers
                </Text>
              </>
            ) : null}
            {checkWalogs.map((a: any, key: any) => (
              <p key={key} style={{ fontSize: 12, color: "#7F56D9" }}>
                {a}
              </p>
            ))}
          </div>
        </Col>
      </Row>
      {modalshow === "upload" ? (
        <UploadModal
          show={modalshow === "upload"}
          handleClose={() => setmodalshow("")}
          reload={() => reload()}
        />
      ) : null}
      {modalshow === "sendWa" ? (
        <SendWA
          key={key}
          reload={() => reload()}
          show={modalshow === "sendWa" && key}
          selectedStudentArr={selectedStudentArr}
          handleClose={() => setmodalshow("")}
        />
      ) : null}
    </Container>
  );
};
