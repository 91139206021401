/* eslint-disable no-empty-pattern */
import React, { useState } from "react";
import { Card } from "./styled/login";
import { ButtonComponent, FormInput } from "../Components";
import { Formik, Form, FormikHelpers } from "formik";
import { Logo } from "../Assets";
import { forgotPassword } from "../Services/Function";

type Props = {};

export function ForgotPassword({}: Props) {
  interface Values {
    email: string;
    password: string;
  }

  const [loading, setloading] = useState(false);

  return (
    <div
      className="row align-items-center justify-content-center"
      style={{ height: "100vh", background: "#7F56D9" }}
    >
      <Card>
        <>
          <Logo color="rgb(127, 86, 217)" />
          <Formik
            initialValues={{
              password: "",
              email: "",
            }}
            onSubmit={async (
              values: Values,
              { setSubmitting }: FormikHelpers<Values>
            ) => {
              setloading(true);
              try {
                await forgotPassword(values.email);
                alert(`Reset email sent to ${values.email}`);
              } catch (error) {
                console.log(error);
                alert(error);
              }
              setloading(false);
            }}
          >
            <Form style={{ width: "70%" }} className="d-flex flex-column">
              <FormInput
                id="email"
                name="email"
                placeholder="aamish@try.city"
                type="email"
                label="Email"
              />

              <div className="d-flex justify-content-between">
                <ButtonComponent
                  btnType="dark"
                  loading={loading}
                  disabled={loading}
                  label="Submit"
                />
              </div>
            </Form>
          </Formik>
        </>
      </Card>
    </div>
  );
}
