/* eslint-disable array-callback-return */
import * as React from "react";

import { DotIcon } from "../../Assets";
import { deleteFee, getUsersWithoutPagination } from "../../Services/Function";
// import { DetailModal } from "../DetailModal";
import { Loader } from "../Loader/Loader";
import Dropdown from "react-bootstrap/Dropdown";
import "./style.scss";
import {
  Table,
  TBody,
  Td,
  TdText,
  Th,
  THead,
  Tr,
  Avatar,
  HoverText,
  AssignTo,
  HiddenDiv,
  NoAvatar,
  UserSection,
} from "./styled";
import { DeleteModal } from "../DeleteModal";
import { useSelector } from "react-redux";
import moment from "moment";
import { ToggleButton } from "../ToggleButton";
import { EditFeeModal } from "..";
import { OtherInfo } from "../LeadsView/styled";
import { DetailModal } from "../DetailModal";
import { TdEmail } from "../ListView/styled";

// import { Form, FormCheck } from "react-bootstrap";

interface IFeesViewProps {
  fees?: any[];
  reloadFees?: any;
  page: number;
  loading: boolean;
  setloading: any;
  feeType:
    | "student-visa"
    | "ielts-booking"
    | "office-fees"
    | "english-self-funded";
}

export const FeesView: React.FunctionComponent<IFeesViewProps> = (props) => {
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [selectedFees, setselectedFees] = React.useState<any>({});
  const [users, setusers] = React.useState<any>([]);
  const [modalShow, setmodalShow] = React.useState<any>("");

  // const { selectedStudentArr, setselectedStudentArr } = props;

  React.useEffect(() => {
    async function fetchData() {
      const res1 = await getUsersWithoutPagination();
      setusers(res1);
    }
    fetchData();
  }, []);

  const handleToggle = async (id: string, currentStatus: boolean, tag: any) => {
    try {
      props.setloading(true);

      // Toggle the status
      const updatedStatus = !currentStatus;
      console.log(tag);
      // Call your update API or state updater
      // await updateFees(id, {
      //   tag: {
      //     ...tag,
      //     moneyTransferdToSchool: updatedStatus,
      //   },
      // });

      // Reload or update the fees state
      if (props.reloadFees) {
        await props.reloadFees();
      }

      props.setloading(false);
    } catch (error) {
      console.error("Error updating toggle:", error);
      props.setloading(false);
    }
  };

  const renderTableData = () => {
    switch (props.feeType) {
      case "student-visa":
        return props.fees?.map((z: any, key: any) => (
          <Tr key={key}>
            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.fullName}</TdText>
                <TdEmail>
                  {moment(z.tag.dateSubmitted).format("DD-MM-YYYY")}
                </TdEmail>
              </div>
            </Td>

            <Td>
              <TdText>{z.tag.phoneNo}</TdText>
            </Td>
            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.vfsRefNo}</TdText>
                <TdEmail>{z.tag.vfsEmail}</TdEmail>
              </div>
            </Td>

            <Td>
              <TdText>
                {moment(z.tag.dateOfAppointment).format("DD-MM-YYYY")}
              </TdText>
            </Td>
            <Td>
              {" "}
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.amount ? `${z.tag.amount} Kd` : "N/A"}</TdText>
                <TdEmail>{z.tag.formOfPayment}</TdEmail>
              </div>
            </Td>

            {/* <Td>
              <TdText>{z.tag.paymentRecieved}</TdText>
            </Td> */}
            <Td>
              <div className="d-flex flex-row" style={{ position: "relative" }}>
                <TdText style={{ fontSize: 14, position: "relative" }}>
                  <HoverText>
                    {users?.filter((x: any) => z.tag.incharge === x.id)[0]
                      ?.avatar ? (
                      <AssignTo style={{ marginRight: 4 }}>
                        <Avatar
                          src={
                            users.filter((x: any) => z.tag.incharge === x.id)[0]
                              .avatar
                          }
                          alt="img"
                        />
                      </AssignTo>
                    ) : (
                      <NoAvatar>
                        {" "}
                        {
                          users.filter((x: any) => z.tag.incharge === x.id)[0]
                            .name[0]
                        }
                      </NoAvatar>
                    )}
                  </HoverText>

                  <HiddenDiv style={{ padding: 10, top: 45 }}>
                    {users.filter((x: any) => z.tag.incharge === x.id)[0].name}{" "}
                    <br /> Incharge
                  </HiddenDiv>
                </TdText>
                <TdText style={{ fontSize: 14, position: "relative" }}>
                  {users?.filter((x: any) => z.tag.checkedBy === x.id)[0]
                    ?.avatar ? (
                    <HoverText>
                      <AssignTo style={{ marginRight: 4, padding: 0 }}>
                        <Avatar
                          src={
                            users.filter(
                              (x: any) => z.tag.checkedBy === x.id
                            )[0].avatar
                          }
                          alt="img"
                        />
                      </AssignTo>
                    </HoverText>
                  ) : (
                    <NoAvatar style={{ right: 0 }}>
                      {
                        users.filter((x: any) => z.tag.checkedBy === x.id)[0]
                          .name[0]
                      }
                    </NoAvatar>
                  )}

                  <HiddenDiv style={{ padding: 10, top: 45 }}>
                    {users.filter((x: any) => z.tag.checkedBy === x.id)[0].name}{" "}
                    <br /> Checked By
                  </HiddenDiv>
                </TdText>
              </div>
            </Td>

            <Td>
              <ToggleButton
                label={""}
                onChange={() => {}}
                checked={z.tag.vfsUploaded}
              />
            </Td>
            <Td style={{ position: "relative" }}>
              <Dropdown>
                <Dropdown.Toggle
                  className="dd-custom-toggle"
                  id="dropdown-custom-components"
                >
                  <DotIcon style={{ cursor: "pointer" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dd-custom-menu">
                  <Dropdown.Item
                    className="dd-custom-items"
                    onClick={async () => {
                      setmodalShow("edit");
                      setselectedFees(z);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dd-custom-items"
                    onClick={async () => {
                      setdeleteModal(true);
                      setselectedFees(z);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Td>
          </Tr>
        ));
      case "ielts-booking":
        return props.fees?.map((z: any, key: any) => (
          <Tr key={key}>
            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.fullName}</TdText>
                <TdEmail>
                  {moment(z.tag.dateSubmitted).format("DD-MM-YYYY")}
                </TdEmail>
              </div>
            </Td>
            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.phoneNo}</TdText>
                <TdEmail>{z.tag.passportNo}</TdEmail>
              </div>
            </Td>

            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.email}</TdText>
                <TdEmail>{z.tag.password}</TdEmail>
              </div>
            </Td>

            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.typeOfTest}</TdText>
                <TdEmail>{z.tag.refNo}</TdEmail>
              </div>
            </Td>

            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.formOfTest}</TdText>
                <TdEmail>
                  {moment(z.tag.dateOfTest).format("DD-MM-YYYY")}
                </TdEmail>
              </div>
            </Td>

            <Td>
              <TdText style={{ fontSize: 14, position: "relative" }}>
                <HoverText>
                  {users?.filter((x: any) => z.tag.incharge === x.id)[0]
                    ?.avatar ? (
                    <AssignTo style={{ marginRight: 4 }}>
                      <Avatar
                        src={
                          users.filter((x: any) => z.tag.incharge === x.id)[0]
                            .avatar
                        }
                        alt="img"
                      />
                    </AssignTo>
                  ) : (
                    <NoAvatar style={{ right: 0 }}>
                      {
                        users.filter((x: any) => z.tag.incharge === x.id)[0]
                          ?.name[0]
                      }
                    </NoAvatar>
                  )}
                </HoverText>

                <HiddenDiv style={{ padding: 10, top: 45 }}>
                  {users.filter((x: any) => z.tag.incharge === x.id)[0]?.name}
                </HiddenDiv>
              </TdText>
            </Td>
            <Td>
              <ToggleButton
                label={""}
                onChange={() => {}}
                checked={z.tag.docSent}
              />
            </Td>
            <Td style={{ position: "relative" }}>
              <Dropdown>
                <Dropdown.Toggle
                  className="dd-custom-toggle"
                  id="dropdown-custom-components"
                >
                  <DotIcon style={{ cursor: "pointer" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dd-custom-menu">
                  <Dropdown.Item
                    className="dd-custom-items"
                    onClick={async () => {
                      setmodalShow("edit");
                      setselectedFees(z);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dd-custom-items"
                    onClick={async () => {
                      setdeleteModal(true);
                      setselectedFees(z);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Td>
          </Tr>
        ));
      case "office-fees":
        return props.fees
          ?.sort((a: any, b: any) => b.createdDate - a.createdDate)
          .map((z: any, key: any) => (
            <Tr key={key}>
              <Td>
                <TdText style={{ fontSize: 14 }}>
                  {moment(z.createdDate).format("DD-MM-YYYY")}
                </TdText>
              </Td>
              <Td>
                <TdText>{z.tag.fullName}</TdText>
              </Td>
              <Td>
                <TdText style={{ fontSize: 14 }}>{`${z.tag.amount} Kd`}</TdText>
              </Td>
              <Td>
                <TdText style={{ fontSize: 14 }}>
                  {z.tag ? z.tag.type.map((a: any) => a.label).join(", ") : ""}
                </TdText>
              </Td>
              <Td>
                <TdText style={{ fontSize: 14, position: "relative" }}>
                  {users?.filter((x: any) => z.tag.salesPerson === x.id)[0]
                    ?.avatar ? (
                    <HoverText>
                      <AssignTo style={{ marginRight: 4, padding: 0 }}>
                        <Avatar
                          src={
                            users?.filter(
                              (x: any) => z.tag.salesPerson === x.id
                            )[0]?.avatar
                          }
                          alt="img"
                        />
                      </AssignTo>
                    </HoverText>
                  ) : (
                    <NoAvatar>
                      {
                        users?.filter((x: any) => z.tag.salesPerson === x.id)[0]
                          ?.name[0]
                      }
                    </NoAvatar>
                  )}

                  <HiddenDiv style={{ padding: 10, top: 45 }}>
                    {
                      users?.filter((x: any) => z.tag.salesPerson === x.id)[0]
                        ?.name
                    }
                  </HiddenDiv>
                </TdText>
              </Td>
              <Td style={{ position: "relative" }}>
                <Dropdown>
                  <Dropdown.Toggle
                    className="dd-custom-toggle"
                    id="dropdown-custom-components"
                  >
                    <DotIcon style={{ cursor: "pointer" }} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dd-custom-menu">
                    <Dropdown.Item
                      className="dd-custom-items"
                      onClick={async () => {
                        setmodalShow("edit");
                        setselectedFees(z);
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dd-custom-items"
                      onClick={async () => {
                        setdeleteModal(true);
                        setselectedFees(z);
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Td>
            </Tr>
          ));
      case "english-self-funded":
        return props.fees?.map((z: any) => (
          <Tr key={z.id}>
            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.fullName}</TdText>
                <TdEmail>
                  {" "}
                  {moment(z.tag.dateSubmitted).format("DD-MM-YYYY")}
                </TdEmail>
              </div>
            </Td>
            <Td>
              <TdText>{z.tag.phoneNo}</TdText>
            </Td>

            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>{z.tag.location}</TdText>
                <TdEmail>{z.tag?.school?.name || "N/A"}</TdEmail>
              </div>
            </Td>
            <Td>
              <div
                className="d-flex flex-column"
                style={{ position: "relative" }}
              >
                <TdText>
                  {moment(z.tag.arrivalDate).format("DD-MM-YYYY")}
                </TdText>
                <TdEmail>{z.tag.noOfWeeks}</TdEmail>
              </div>
            </Td>

            <Td>
              <TdText>{z.tag.amount}</TdText>
            </Td>
            <Td>
              <ToggleButton
                label={"test"}
                onChange={async () => {
                  // await handleToggle(z.id, z.tag.moneyTransferdToSchool, z.tag);
                }}
                checked={z.tag.moneyTransferdToSchool}
              />
            </Td>

            <Td>
              <div className="d-flex flex-row" style={{ position: "relative" }}>
                <TdText
                  className="d-flex justify-content-center"
                  style={{ fontSize: 14, position: "relative" }}
                >
                  <HoverText>
                    <AssignTo style={{ paddingLeft: 0 }} className="w-100">
                      {z.tag.salesPerson.avatar ? (
                        <Avatar src={z.tag.salesPerson.avatar} alt="img" />
                      ) : (
                        <NoAvatar style={{ right: 0 }}>
                          {z.tag.salesPerson.name[0]}
                        </NoAvatar>
                      )}
                    </AssignTo>
                  </HoverText>

                  <HiddenDiv style={{ padding: 10, top: 45 }}>
                    {z.tag.salesPerson.name} <br /> Sales Person
                  </HiddenDiv>
                </TdText>
                <TdText
                  className="d-flex justify-content-center"
                  style={{ fontSize: 14, position: "relative" }}
                >
                  <HoverText>
                    <AssignTo className="w-100">
                      {z.tag.accountManager.avatar ? (
                        <Avatar src={z.tag.accountManager.avatar} alt="img" />
                      ) : (
                        <NoAvatar style={{ right: 0 }}>
                          {z.tag.accountManager.name[0]}
                        </NoAvatar>
                      )}
                    </AssignTo>
                  </HoverText>

                  <HiddenDiv style={{ padding: 10, top: 45 }}>
                    {z.tag.accountManager.name}
                    <br /> Account Manager
                  </HiddenDiv>
                </TdText>
                <TdText
                  className="d-flex justify-content-center"
                  style={{ fontSize: 14, position: "relative" }}
                >
                  <HoverText>
                    <AssignTo className="w-100">
                      {z.tag.operation.avatar ? (
                        <Avatar src={z.tag.operation.avatar} alt="img" />
                      ) : z.tag.operation ? (
                        <NoAvatar style={{ right: 0 }}>
                          {z.tag.operation?.name[0]}
                        </NoAvatar>
                      ) : null}
                    </AssignTo>
                  </HoverText>

                  <HiddenDiv style={{ padding: 10, top: 45 }}>
                    {z.tag.operation.name} <br /> Operation
                  </HiddenDiv>
                </TdText>
              </div>
            </Td>

            <Td style={{ position: "relative" }}>
              <Dropdown>
                <Dropdown.Toggle
                  className="dd-custom-toggle"
                  id="dropdown-custom-components"
                >
                  <DotIcon style={{ cursor: "pointer" }} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dd-custom-menu">
                  <Dropdown.Item
                    className="dd-custom-items"
                    onClick={async () => {
                      setmodalShow("edit");
                      setselectedFees(z);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dd-custom-items"
                    onClick={async () => {
                      setdeleteModal(true);
                      setselectedFees(z);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Td>
          </Tr>
        ));
      default:
        return null;
    }
  };

  return (
    <div style={{}} className="table-responsive">
      {props.loading ? (
        <div className="d-flex align-items-center w-100 justify-content-center">
          <Loader width="200px" height="200px" />
        </div>
      ) : (
        <Table className="table table-scroll">
          <THead>
            <Tr>
              {/* Render table headers based on feeType */}

              {props.feeType === "student-visa" && (
                <>
                  <Th>Name/Date</Th>
                  <Th>Phone number</Th>
                  <Th>VFS Ref No/Email</Th>
                  <Th>Appointment Date</Th>
                  <Th>Payment/Form of payment</Th>
                  <Th>Assigned</Th>
                  <Th>VFS Uploaded</Th>
                  <Th></Th>
                </>
              )}
              {props.feeType === "ielts-booking" && (
                <>
                  <Th>Name/Date</Th>
                  <Th>Mobile/Passport No.</Th>
                  <Th>Email/Password</Th>
                  <Th>Type of Test/Ref No.</Th>
                  <Th>Form of Test/Date of Test</Th>
                  <Th>Assigned</Th>
                  <Th>Documents Sent</Th>
                  <Th></Th>
                </>
              )}
              {props.feeType === "office-fees" && (
                <>
                  {" "}
                  <Th>Created Date</Th>
                  <Th>Name</Th>
                  <Th>Amount</Th>
                  <Th>Type</Th>
                  <Th>Sales Person</Th>
                  <Th></Th>
                </>
              )}
              {props.feeType === "english-self-funded" && (
                <>
                  <Th>Name/Date</Th>
                  <Th>Mobile</Th>
                  <Th>Location/School</Th>
                  <Th>Arrival Date/weeks</Th>
                  <Th>Payment Amount</Th>
                  <Th>Money Transferred</Th>
                  <Th>Assigned</Th>

                  <Th></Th>
                </>
              )}
              {/* Add other headers based on feeType */}
            </Tr>
          </THead>

          <TBody>{renderTableData()}</TBody>
        </Table>
      )}

      <DeleteModal
        onClick={async () => {
          try {
            props.setloading(true);
            await deleteFee(selectedFees.id);
            props.reloadFees(props.feeType);
            props.setloading(false);
            setselectedFees({});
            setdeleteModal(false);
          } catch (error: any) {
            alert(error);
            setselectedFees({});
            props.setloading(false);
          }
        }}
        onHide={() => setdeleteModal(false)}
        show={deleteModal}
      />

      {modalShow === "edit" ? (
        <EditFeeModal
          show={modalShow === "edit"}
          onHide={() => setmodalShow("")}
          reloadData={() => props.reloadFees(props.feeType)}
          feeType={props.feeType}
          selectedFees={selectedFees}
        />
      ) : null}

      {modalShow === "details" ? (
        <DetailModal
          onHide={() => setmodalShow("")}
          show={modalShow === "detail"}
          students={selectedFees}
          type={props.feeType}
        />
      ) : null}
    </div>
  );
};
