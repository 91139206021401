import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import { createAppliedStudent } from "../../Services/Function";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { ButtonComponent } from "../ButtonComponent";

export const NumberInputModal = (props: {
  show: boolean;
  onHide: () => void;
  userDetails: { id: string };
  reloadData: any;
  users: any;
}) => {
  const { show, onHide, userDetails, reloadData, users } = props;
  const [number, setNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [createdDate, setCreatedDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = React.useState<any>({});

  const handleIncrement = () => setNumber((prevNumber) => prevNumber + 1);
  const handleDecrement = () => setNumber((prevNumber) => prevNumber - 1);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || value === "-") {
      setNumber(0);
    } else {
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        setNumber(parsedValue);
      }
    }
  };
  const options: any = users
    .filter((a: any) => a.role === "64d339237f0db6cdfb0e553c")
    .map((a: any) => ({ value: a.id, label: a.name }));

  const handleSubmit = async () => {
    setLoading(true);
    await createAppliedStudent({
      number,
      createdDate: createdDate,
      userId: userDetails.id,
      counsellor: selectedOption.value,
    });
    setLoading(false);
    setSelectedOption({});
    reloadData();
    setNumber(0);
    setCreatedDate(new Date());
    onHide();
  };

  console.log(selectedOption);
  return (
    <Modal
      centered
      show={show}
      onHide={() => {
        setSelectedOption({});
        setNumber(0);
        setCreatedDate(new Date());
        onHide();
      }}
      size="sm"
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">Add Students Applied</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{}}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit();
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} className="d-flex flex-column">
            <Modal.Body>
              <div
                className="d-flex w-100 align-items-center justify-content-start"
                style={{ marginBottom: 20 }}
              >
                <Button
                  variant="outline-secondary"
                  type="button"
                  onClick={handleDecrement}
                >
                  -
                </Button>
                <Form.Control
                  type="number"
                  value={number.toString()}
                  onChange={handleChange}
                  className="mx-2"
                  style={{ width: "80px", textAlign: "center" }}
                />
                <Button
                  variant="outline-secondary"
                  type="button"
                  onClick={handleIncrement}
                >
                  +
                </Button>
              </div>
              <ReactDatePicker
                selected={createdDate}
                className="form-input"
                onChange={(date: any) => setCreatedDate(date)}
              />
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#7F56D9" : "#d7dfe9",
                    boxShadow: "none",
                    marginTop: 20,
                  }),
                }}
                placeholder="Counsellor..."
                value={selectedOption}
                onChange={(selectedItems: any) =>
                  setSelectedOption(selectedItems)
                }
                options={options}
              />
            </Modal.Body>
            <Modal.Footer className="w-100 d-flex justify-content-start">
              <ButtonComponent
                label={loading ? "Saving..." : "Save"}
                type="submit"
                btnType="dark"
                disabled={loading || !Object.keys(selectedOption).length}
                loading={loading}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
