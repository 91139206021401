/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { CopyIcon, MenuIcon, StarIcon } from "../../Assets";
import {
  ApplicationHeader,
  BadgeCon,
  Card,
  Pointer,
  ShowBtn,
  Subtext,
} from "./styled";
import moment from "moment";
import {
  deleteStudentApplication,
  getCourseById,
  updateApplication,
  updateStudent,
} from "../../Services/Function";
import ApplicationModal from "../ApplicationModal";
import { Badge, Dropdown } from "react-bootstrap";
import { DeleteModal } from "../DeleteModal";
import { Loader } from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { CrossIcon } from "../ListView/styled";
import { useSelector } from "react-redux";

interface IApplicatinCardProps {
  applications: any;
  key: any;
  applicationDetail?: any;
  student: any;
  docUploaded?: any;
  reloadApplication?: any;
  reloadStudents: any;
}

export const ApplicatinCard: React.FunctionComponent<IApplicatinCardProps> = (
  props
) => {
  const { applications } = props;
  const [loading, setLoading] = React.useState(true); // Loading state
  const [course, setcourse] = React.useState<any>([]);
  const [courseDuration, setcourseDuration] = React.useState("");
  const [attendanceType, setattendanceType] = React.useState("");
  const [applicationPhases, setapplicationPhases] = React.useState<any>([]);
  const [modalShow, setmodalShow] = React.useState<any>(false);
  const auth = useSelector((state: any) => state.auth);

  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const res = await getCourseById(applications.course);
        setcourse(res.data);
        setcourseDuration(
          res.data?.courseDurationValues[0] === 360
            ? "1 Year"
            : res.data?.courseDurationValues[0] === 720
              ? "2 Years"
              : res.data?.courseDurationValues[0] === 1080
                ? "3 Years"
                : "4 Years"
        );
        setattendanceType(res.data?.attendanceTypes[0]);
        setapplicationPhases(
          applications.portalApplicationStatus.applicationPhases.filter(
            // eslint-disable-next-line array-callback-return
            (a: any) => {
              if (a.isCurrent === true) {
                return a;
              }
            }
          )
        );
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    fetchData();
  }, []);

  // if (loading) {
  //   return (
  //     <div style={{ padding: "50px 0" }}>
  //       <Loader />
  //     </div>
  //   ); // Render loading indicator
  // }

  const bg = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ];

  return (
    <div>
      <Card key={applications._id}>
        <div className="d-flex flex-column justify-content-center">
          <ApplicationHeader
            onClick={() =>
              navigate(
                `/student/applicationDetails/${applications?.studentId}/${applications?._id}`
              )
            }
          >
            {course.name}{" "}
            {applications.bachelorYear ? applications.bachelorYear : ""}
          </ApplicationHeader>
          <div
            className="d-flex flex-row align-items-center"
            style={{ marginBottom: 6.5 }}
          >
            <img
              width="36px"
              height="36px"
              src={`https://storage.googleapis.com/uapply/${applications.institute.logoUrl}`}
              alt="icon"
            />
            <>{course.institution}</>

            {applications.portalApplicationStatus?.applicationPhases.filter(
              (z: any) => z.status === "Done" && z.phaseState === "Completed"
            ).length ? (
              <>
                <Pointer />
                <ShowBtn color="#006644" background="#E3FCEF">
                  <StarIcon style={{ marginRight: 4 }} />
                  Final Choice
                </ShowBtn>
              </>
            ) : null}
          </div>
          <div
            className="d-flex flex-row align-items-center"
            style={{ marginBottom: 6.5 }}
          >
            <Subtext color="#5E6C84">
              Application No.: <b> {applications.applicationId}</b>
            </Subtext>
            <CopyIcon style={{ marginLeft: 6 }} />

            {applications.agent ? (
              <>
                <Pointer />
                <ShowBtn color="#0747A6" background="#EBF3FF">
                  {applications.agent}
                </ShowBtn>
              </>
            ) : null}
          </div>
          <Subtext color="#5E6C84">
            Created on{" "}
            {moment(applications.startDate).format("DD-MM-YYYY hh:mma")}
          </Subtext>
        </div>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column">
            <BadgeCon>
              {applications.courseLevel} • {attendanceType} • {courseDuration}
            </BadgeCon>
            <div
              className="d-flex flex-row align-items-center"
              style={{ marginTop: 12 }}
            >
              <div>
                {applications.portalApplicationStatus?.applicationPhases.filter(
                  (z: any) => z.status === "Submitted"
                )[0].isPrevious ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dd-custom-toggle "
                      id="dropdown-custom-components"
                      style={{ padding: 0 }}
                    >
                      <div className="d-flex flex-row flex-wrap w-100">
                        {applications.rejected ? (
                          <div
                            className="d-flex flex-column"
                            style={{
                              position: "relative",
                              marginRight: 12,
                            }}
                          >
                            <Badge
                              pill
                              bg={applications.rejected ? "danger" : "success"}
                              style={{
                                marginBottom: 4,
                                cursor: "pointer",
                              }}
                            >
                              {applications.rejected ? "Rejected" : "Approved"}
                            </Badge>
                            <CrossIcon
                              onClick={async () => {
                                try {
                                  setLoading(true);
                                  await updateApplication(applications._id, {
                                    rejected: false,
                                  });
                                  await props.reloadApplication();
                                  setLoading(false);
                                } catch (error: any) {
                                  setLoading(false);
                                  alert(error.response.data.message);
                                }
                              }}
                            >
                              <div style={{ marginBottom: 4 }}>x</div>
                            </CrossIcon>
                          </div>
                        ) : (
                          <div
                            className=" d-flex align-items-center w-100"
                            style={{ cursor: "pointer" }}
                          >
                            <Subtext color="#5E6C84">Status: &nbsp;</Subtext>
                            <Subtext color="#0052CC" fontWeight={700}>
                              Draft
                            </Subtext>
                          </div>
                        )}
                      </div>
                    </Dropdown.Toggle>

                    {/* {showMenu === z.id ? ( */}
                    <Dropdown.Menu>
                      {["Rejected"].map((a: any, key: any) => (
                        <Dropdown.Item>
                          <Badge
                            pill
                            className="d-flex flex-column"
                            key={key}
                            style={{ marginBottom: 10 }}
                            onClick={async () => {
                              try {
                                setLoading(true);
                                await updateApplication(applications._id, {
                                  rejected: a === "Rejected",
                                });
                                await props.reloadApplication();
                                setLoading(false);
                              } catch (error: any) {
                                setLoading(false);
                                alert(error.response.data.message);
                              }
                            }}
                            bg={"danger"}
                          >
                            {a}
                          </Badge>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <div
                    className=" d-flex align-items-center w-100"
                    style={{ cursor: "pointer" }}
                  >
                    <Subtext color="#5E6C84">Status: &nbsp;</Subtext>
                    <Subtext color="#0052CC" fontWeight={700}>
                      Draft
                    </Subtext>
                  </div>
                )}
              </div>
              <Subtext color="#5E6C84" fontWeight={700}>
                &nbsp; |{" "}
                {applications.portalApplicationStatus?.applicationPhases.filter(
                  (z: any) => z.isPrevious
                )[0].status === "Submitted"
                  ? "Ready to apply"
                  : applications.portalApplicationStatus?.applicationPhases.filter(
                      (z: any) => z.isPrevious
                    )[0].status}
              </Subtext>
            </div>
            <div>
              {applications.portalApplicationStatus?.applicationPhases.filter(
                (z: any) => z.status === "Confirmation"
              )[0].phaseState === "Completed" ? (
                <Dropdown>
                  <Dropdown.Toggle
                    className="dd-custom-toggle "
                    id="dropdown-custom-components"
                    style={{ padding: 0 }}
                  >
                    <div className="d-flex flex-row flex-wrap w-100">
                      {applications.status.length ? (
                        applications.status?.map((st: string, key: any) => (
                          <div
                            className="d-flex flex-column"
                            style={{
                              position: "relative",
                              marginRight: 12,
                            }}
                            key={key}
                          >
                            <Badge
                              pill
                              bg={st === "Offer on KCO" ? "info" : "success"}
                              style={{
                                marginBottom: 4,
                                cursor: "pointer",
                              }}
                            >
                              {st}
                            </Badge>
                            <CrossIcon
                              onClick={async () => {
                                try {
                                  setLoading(true);
                                  const updated = applications.status.filter(
                                    (x: any) => x !== st
                                  );
                                  await updateApplication(applications._id, {
                                    status: updated,
                                  });
                                  await props.reloadApplication();
                                  setLoading(false);
                                } catch (error: any) {
                                  setLoading(false);
                                  alert(error.response.data.message);
                                }
                              }}
                            >
                              <div style={{ marginBottom: 4 }}>x</div>
                            </CrossIcon>
                          </div>
                        ))
                      ) : (
                        <div>
                          <Subtext color="#5E6C84">Status: &nbsp;</Subtext>
                          <Subtext color="#0052CC" fontWeight={700}>
                            Draft
                          </Subtext>
                        </div>
                      )}
                    </div>
                  </Dropdown.Toggle>

                  {/* {showMenu === z.id ? ( */}
                  <Dropdown.Menu>
                    {["Offer on KCO", "KCO Approved", "Status 3"].map(
                      (a: any, key: any) => (
                        <Dropdown.Item>
                          <Badge
                            pill
                            className="d-flex flex-column"
                            key={key}
                            style={{ marginBottom: 10 }}
                            onClick={async () => {
                              try {
                                setLoading(true);
                                await updateApplication(applications._id, {
                                  status: a,
                                });
                                await props.reloadApplication();
                                setLoading(false);
                              } catch (error: any) {
                                setLoading(false);
                                alert(error.response.data.message);
                              }
                            }}
                            bg={a === "Offer on KCO" ? "info" : "success"}
                          >
                            {a}
                          </Badge>
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </div>
          </div>

          <Dropdown style={{ marginBottom: 10 }}>
            <Dropdown.Toggle
              className="menu-dd"
              style={{ border: "none !important" }}
            >
              <MenuIcon style={{ cursor: "pointer", marginLeft: 12 }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setmodalShow("delete");
                }}
                eventKey="1"
              >
                Delete
              </Dropdown.Item>

              {props.student.onHold && !applications.finalChoice ? null : (
                <Dropdown>
                  <Dropdown.Toggle
                    className="submenu-dd"
                    style={{
                      border: "none !important",
                      backgroundColor: "transparent",
                    }}
                  >
                    Stages
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ width: "auto" }}>
                    {[
                      "Submitted",
                      "Conditional offer",
                      "Unconditional offer",
                      "Confirmation",
                      "FG/BS",
                      "CAS Received",
                      "Done",
                    ].map((z: string) => (
                      <Dropdown.Item
                        onClick={async () => {
                          let stages =
                            applications.portalApplicationStatus
                              ?.applicationPhases;

                          const selectedStatus = z; // Replace this with the status you want to select
                          const selectedStageIndex = stages.findIndex(
                            (stage: any) => stage.status === selectedStatus
                          );

                          if (selectedStageIndex !== -1) {
                            // Loop through all stages to update phase states and flags
                            stages.forEach((stage: any, index: any) => {
                              if (index < selectedStageIndex) {
                                // For stages before the selected stage
                                if (index === selectedStageIndex - 1) {
                                  // The stage right before the selected stage is marked as isPrevious true
                                  stage.isPrevious = true;
                                } else {
                                  // All other stages before the selected stage
                                  stage.isPrevious = false;
                                }
                                stage.phaseState = "Completed";
                                stage.isCurrent = false;
                              } else if (index === selectedStageIndex) {
                                // The selected stage is set as current with phaseState 'AwaitingResponseStudent'
                                stage.phaseState = "AwaitingResponseStudent";
                                stage.isCurrent = true;
                                stage.isPrevious = false;
                              } else {
                                // Stages after the selected stage are set to upcoming
                                stage.phaseState = "Upcoming";
                                stage.isCurrent = false;
                                stage.isPrevious = false;
                              }
                            });
                          }

                          const confirmationStage = stages.find(
                            (stage: any) => stage.status === "Confirmation"
                          );

                          if (
                            confirmationStage.phaseState ===
                              "AwaitingResponseStudent" ||
                            confirmationStage.phaseState === "Completed"
                          ) {
                            await updateStudent(applications?.studentId, {
                              onHold: true,
                            });
                            await updateApplication(applications._id, {
                              finalChoice: true,
                            });
                          } else if (
                            confirmationStage.phaseState === "Upcoming"
                          ) {
                            await updateApplication(applications._id, {
                              finalChoice: false,
                            });
                            await updateStudent(applications?.studentId, {
                              onHold: false,
                            });
                          }
                          await updateApplication(applications._id, {
                            portalApplicationStatus: {
                              applicationPhases: stages,
                            },
                            phaseChanged: true,
                            editor: auth.userDetails,
                          });

                          props.reloadApplication();
                          props.reloadStudents();
                        }}
                        eventKey="2"
                      >
                        {z}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card>
      {modalShow === "ready-to-apply" ? (
        <ApplicationModal
          show={modalShow}
          handleClose={() => setmodalShow(false)}
        />
      ) : null}

      {modalShow === "delete" ? (
        <DeleteModal
          show={modalShow}
          onHide={() => setmodalShow(false)}
          onClick={async () => {
            await deleteStudentApplication(applications._id);
            await updateStudent(applications?.studentId, {
              applications: props.student?.applications.filter(
                (a: any) => a !== applications._id
              ),
              stage:
                props.student?.applications.filter(
                  (a: any) => a !== applications._id
                ).length === 0
                  ? "NotApplied"
                  : "Applied",
            });
            window.location.replace(
              `/student/applications/${applications?.studentId}`
            );
          }}
        />
      ) : null}
    </div>
  );
};
